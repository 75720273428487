import React, { useState } from "react";
import styled from "styled-components";

import CommandList from "./list";
import CommandPanel from "./command";
import { useCommands } from "../../data/CommandHook";
import { useParams } from "react-router-dom";

interface Params {
  command?: string;
  group?: string;
  sub?: string;
}

const CommandAdmin = () => {
  const data = useCommands();
  useParams<Params>();
  const [language, setLanguage] = useState<string>("en");
  const [command, group, sub] = window.location.pathname.substr(16).split("/");

  const cmd = data?.find((c) => c.id === command);

  return (
    <Style>
      <CommandList commands={data} language={language} />
      {command && (
        <CommandPanel
          command={cmd}
          subgroup={sub ? group : undefined}
          subcommand={sub ? sub : group}
          language={language}
          setLanguage={(langauge) => setLanguage(langauge)}
        />
      )}
    </Style>
  );
};

const Style = styled.div`
  display: flex;
`;

export default CommandAdmin;
