import React from "react";
import styled from "styled-components";
import ICommand, { ISubCommand, ISubGroup } from "../../interfaces/ICommand";
import { Link } from "react-router-dom";

interface Props {
  language: string;
  commands?: Array<ICommand>;
}

const CommandListPanel: React.FC<Props> = ({ language, commands }) => {
  return (
    <Style>
      <h2>
        Categories <Link to="/admin/commands/new" className="icon icon-math-plus" />
      </h2>
      <ul>{commands && commands.map((c) => <ListItem key={c.id} command={c} language={language} />)}</ul>
    </Style>
  );
};

interface ItemProps {
  command: ICommand;
  language: string;
  path?: string;
  parent?: string;
}

const ListItem: React.FC<ItemProps> = ({ command, language, path = "", parent = "/" }) => {
  const cmdpath = `${path}/${command.id}`;
  const name = getField(command, "name", language);
  const allName = `${parent}${parent.length > 1 ? " " : ""}${name}`;

  if (command.subgroups || command.subcommands) {
    return (
      <>
        {command.subgroups &&
          Object.values(command.subgroups)
            .filter((g) => (g.subcommands ? true : false))
            .map((g: ISubGroup) => {
              const itempath = `${cmdpath}/${g.id}`;
              return <ListItem key={itempath} command={g} language={language} path={cmdpath} parent={`${allName} `} />;
            })}
        {command.subcommands &&
          Object.values(command.subcommands).map((g: ISubCommand) => {
            const itempath = `${cmdpath}/${g.id}`;
            return <ListItem key={itempath} command={g} language={language} path={cmdpath} parent={`${allName} `} />;
          })}
      </>
    );
  } else {
    const url = `/admin/commands${cmdpath}`;
    return (
      <li>
        <Link to={url} data-selected={window.location.pathname.startsWith(url) ? true : undefined}>
          <img src={command.image ? command.image : "/artimus.png"} alt={allName} />
          {allName}
          <br />
          <span>{getField(command, "description", language)}</span>
        </Link>
      </li>
    );
  }
};

const getField = (obj: any, field: string, language: string): string => {
  if (typeof obj[field] === "string") {
    return obj[field];
  }
  if (!obj[field]) {
    console.log("Unknown Field", field);
    return "";
  }
  return obj[field][language].value;
};

const Style = styled.div`
  position: fixed;
  top: 71px;
  left: 0;
  flex: 0;
  width: 390px;
  max-height: calc(100vh - 71px);
  padding: 1rem 0.6rem 1rem 0.3rem;
  background: #1b1e2b;
  color: #4d6ca2;

  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  &:not(:hover) > *::-webkit-scrollbar-thumb {
    background: transparent;
    opacity: 0;
  }

  h2 {
    position: relative;
    font-size: 1.2em;
    text-transform: uppercase;
    color: #4d6ca2;
    margin: 0 0 1em 0;
    color: #4d6ca2;
    flex: 0;
    padding: 0 1rem;

    .icon {
      height: 100%;
      aspect-ratio: 1;
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
      border-radius: 5px;
      background: rgba(0, 0, 0, 0.1);
      margin-right: 0.9rem;
      text-decoration: none;
      color: #757ca1;

      &:hover {
        background: rgba(0, 0, 0, 0.25);
      }
    }
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: auto;
    padding: 0 0.9rem 0 1rem;
    flex: 1;
  }

  li:not(:last-child) {
    margin-bottom: 10px;
  }

  li > a {
    display: block;
    text-decoration: none;
    position: relative;
    line-height: 1.2rem;
    font-weight: bold;
    color: #68a59f;
    cursor: pointer;
    padding: 0.8rem 0.8rem 0.8rem 4rem;
    border-radius: 5px;
    overflow: hidden;
    white-space: nowrap;
    background: rgba(0, 0, 0, 0.1);
    transition: color 0.25s;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
    text-overflow: ellipsis;

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }

    &[data-selected="true"],
    &:hover {
      background: rgba(0, 0, 0, 0.25);
      span {
        color: #b2ccd6;
      }
    }

    img {
      position: absolute;
      width: 2.4rem;
      height: 2.4rem;
      object-fit: cover;
      left: 0.8rem;
      top: 0.8rem;
      border-radius: 30%;
    }

    span {
      color: #4d6ca2;
      font-size: 1rem;
      font-weight: normal;
      text-overflow: ellipsis;
      transition: color 0.25s;
      font-size: 0.9rem;
    }
  }
`;

export default CommandListPanel;
