import { useEffect, useState } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getDatabase, ref, get } from "firebase/database";
import styled from "styled-components";
import Slider from "../../../components/Slider";
import RoleDropDown from "./RoleDropDown";
import VerificationMessage from "./VerificationMessage";

interface Props {
  guild: any;
}

const VerifyRoles = ({ guild }: Props) => {
  const [saving, setSaving] = useState(false);
  const [nickname, setNickname] = useState(true);
  const [role, setRole] = useState({
    all: "",
    pc: "",
    xbox: "",
    psn: "",
    nsw: "",
  });

  useEffect(() => {
    if (!guild) return;
    load(guild.id).then((data) => {
      if (!data) return;
      setNickname(data.nickname);
      setRole(data.roles);
    });
  }, [guild]);

  return (
    <>
      <div>
        <h2>Update Nickname</h2>
        <Slider checked={nickname} onChange={(val) => setNickname(val)} /> Set server nickname to user's In Game Name
        (IGN).
      </div>
      <div>
        <h2>Assign Platform Role</h2>
        <table>
          <tbody>
            <tr>
              <td>Verified</td>
              <td>
                <RoleDropDown
                  value={role.all ?? ""}
                  createText={role.all !== "new" ? "Create Role" : undefined}
                  roles={getRoles(guild?.roles, role.all, "Verified", 0)}
                  onChange={(val) => setRole({ ...role, all: val })}
                  onCreate={() => setRole({ ...role, all: "new" })}
                />
              </td>
            </tr>
            <tr>
              <td>PC</td>
              <td>
                <RoleDropDown
                  value={role.pc}
                  createText={role.pc !== "new" ? "Create PC Role" : undefined}
                  roles={getRoles(guild?.roles, role.pc, "PC Recruitment", 0)}
                  onChange={(val) => setRole({ ...role, pc: val })}
                  onCreate={() => setRole({ ...role, pc: "new" })}
                />
              </td>
            </tr>
            <tr>
              <td>XBox</td>
              <td>
                <RoleDropDown
                  value={role.xbox}
                  createText={role.xbox !== "new" ? "Create XBox Role" : undefined}
                  roles={getRoles(guild?.roles, role.xbox, "XBox Recruitment", 3066993)}
                  onChange={(val) => setRole({ ...role, xbox: val })}
                  onCreate={() => setRole({ ...role, xbox: "new" })}
                />
              </td>
            </tr>
            <tr>
              <td>Playstation</td>
              <td>
                <RoleDropDown
                  value={role.psn}
                  createText={role.psn !== "new" ? "Create Playstation Role" : undefined}
                  roles={getRoles(guild?.roles, role.psn, "Playstation Recruitment", 3447003)}
                  onChange={(val) => setRole({ ...role, psn: val })}
                  onCreate={() => setRole({ ...role, psn: "new" })}
                />
              </td>
            </tr>
            <tr>
              <td>Switch</td>
              <td>
                <RoleDropDown
                  value={role.nsw}
                  createText={role.nsw !== "new" ? "Create Switch Role" : undefined}
                  roles={getRoles(guild?.roles, role.nsw, "Switch Recruitment", 15277667)}
                  onChange={(val) => setRole({ ...role, nsw: val })}
                  onCreate={() => setRole({ ...role, nsw: "new" })}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div>
          <button
            disabled={saving}
            onClick={() => {
              setSaving(true);
              save({ id: guild.id, nickname: nickname, roles: role }).then(() => setSaving(false));
            }}
          >
            Save
          </button>
          <button
            disabled={saving}
            onClick={() => {
              load(guild.id).then((data) => {
                if (!data) return;
                setNickname(data.nickname);
                setRole(data.roles);
              });
            }}
          >
            Reset
          </button>
        </div>
      </div>
    </>
  );
};

const save = async (data: any): Promise<any> => {
  const functions = getFunctions();
  const discordGuildVerify = httpsCallable(functions, "discordGuildVerify");
  return discordGuildVerify(data).then((result) => {
    console.log("saved");
  });
};

const load = async (id: string): Promise<any> => {
  return get(ref(getDatabase(), `guilds/${id}/verify`)).then((snapshot) => {
    if (!snapshot.exists()) return;
    return snapshot.val();
  });
};

const getRoles = (roles: Array<any> | undefined, value: string, newName: string, newColor: number): Array<any> => {
  if (!roles) roles = [];
  if (value !== "new") return roles;
  return [...roles, { id: "new", name: newName, color: newColor }];
};

const Style = styled.div``;

export default VerifyRoles;
