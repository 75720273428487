import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

const files: {
  [key: string]: File;
} = {};

const createTemplate = (id: string, src?: string, caption?: string): string => {
  if (!caption) caption = "Caption Goes Here";
  return `<div class="figure" contenteditable="false"><figure><img data-image='${id}' src='${src}'></img><figcaption contenteditable="true"><div>${caption}</div></figcaption></figure></div>`;
};

const removeEditable = async (html: string): Promise<string> => {
  let newHtml = html;
  let reg = /<img [^>]+>/gim;
  let result;
  while ((result = reg.exec(html)) !== null) {
    const match = /data-image="([^"]+)"/gim.exec(result[0]);
    if (match) {
      const key = match[1];
      const file = files[key];
      let url;
      if (file) {
        const path = `pages/${randID()}.${file.name.split(".").pop()?.toLowerCase()}`;
        url = await uploadBytes(ref(getStorage(), path), file).then((snapshot) => getDownloadURL(snapshot.ref));
      }

      console.log("id", url);
      const replaced = `<img src='${url}'>`;
      newHtml = newHtml.replace(result[0], replaced);
    }
  }

  return newHtml
    .replace(/<div class="figure" contenteditable="false">/gi, `<div class="figure">`)
    .replace(/<figcaption[^>]+>/gi, `<figcaption>`);
};

const addEditable = (html: string): string => {
  return html
    .replace(/<div class="figure">/gi, `<div class="figure" contenteditable="false">`)
    .replace(/<figcaption>/gi, `<figcaption contenteditable="true">`);
};

const selectImage = (): Promise<string | null> => {
  return new Promise<string | null>((resolve, reject) => {
    const input = document.createElement("input");
    input.type = "file";
    const onChange = (e: any) => {
      removeEvents();

      const file = input.files?.[0];
      if (!file) {
        resolve(null);
        return;
      }

      const extension = file.name.split(".").pop()?.toLowerCase();
      switch (extension) {
        case "png":
        case "jpg":
        case "jpeg":
        case "gif":
          break;
        default:
          reject("Please select an image file.");
          return;
      }

      const reader = new FileReader();
      reader.onload = (e: any) => {
        const key = randID();
        files[key] = file;
        resolve(createTemplate(key, e.target.result, file.name));
      };
      reader.readAsDataURL(file);
    };
    const timeout = setTimeout(() => {
      removeEvents();
      resolve(null);
    }, 1800000);
    const removeEvents = () => {
      input.removeEventListener("change", onChange);
      clearTimeout(timeout);
    };
    input.addEventListener("change", onChange);
    input.click();
  });
};

const randID = () => {
  return new Array(20).join().replace(/(.|$)/g, function () {
    return ((Math.random() * 36) | 0).toString(36);
  });
};

export default {
  removeEditable,
  addEditable,
  selectImage,
};
