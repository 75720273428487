import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { getAuth, signInWithCustomToken } from "firebase/auth";

const OAuth = () => {
  const history = useHistory();
  const params = new URLSearchParams(window.location.search);
  const path = params.has("state") ? (params.get("state") as string) : "/";

  useEffect(() => {
    console.log("auth");
    if (!params.has("code")) {
      history.push(path);
      return;
    }

    //authenticate code
    fetch("/oauth/login.json", {
      method: "POST",
      body: `code=${params.get("code")}`,
    })
      .then((response) => response.json())
      .then((user) => {
        if (!user.id) return;
        if (user.token) {
          signInWithCustomToken(getAuth(), user.token).then((fbuser) => {
            //localStorage.setItem("user", JSON.stringify(fbuser.user));
            setTimeout(() => history.push(path), 40);
          });
        }
      });

    // eslint-disable-next-line
  }, []);

  return <></>;
};

export default OAuth;
