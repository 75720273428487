import { useEffect, useState } from "react";
import { getDatabase, ref, onValue } from "firebase/database";
import ICommand from "../interfaces/ICommand";

let commands: Array<ICommand>;
let init = false;

const listeners = new Set<() => void>();

const loadCommands = () => {
  init = true;
  let commandRef = ref(getDatabase(), "games/warframe/commands");
  onValue(commandRef, (snapshot) => {
    const response = snapshot.val();
    const newCommands = Object.entries(response).map(([key, value]) => {
      const cmd: ICommand = { ...(value as any) };
      if (!cmd.id) cmd.id = key;
      return cmd;
    }) as Array<ICommand>;

    newCommands.forEach((c) => {
      if (!c.subcommands) return;
      Object.entries(c.subcommands).forEach(([key, value]) => {
        if (!value.name) {
          value.id = key;
        }
      });
    });

    commands = newCommands;

    listeners.forEach((cb) => cb());
  });
};

const addListener = (cb: () => void) => {
  listeners.add(cb);
  if (!init) loadCommands();
};

const removeListener = (cb: () => void) => {
  listeners.delete(cb);
};

export const useCommands = (): Array<ICommand> | undefined => {
  const [data, toggleData] = useState(false);
  useEffect(() => {
    const cb = () => toggleData(!data);
    addListener(cb);
    return () => removeListener(cb);
  });
  return commands;
};
