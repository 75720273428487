import { RouteProps } from "react-router-dom";
import { ParsedToken } from "firebase/auth";
import Login from "../pages/login";

interface Props extends RouteProps {
  user?: ParsedToken | null;
}

const PrivateRoute: React.FC<Props> = ({ user, children }: Props) => {
  return <>{user?.administrator ? children : <Login />}</>;
};

export default PrivateRoute;
