import { MouseEvent } from "react";
import styled from "styled-components";

interface Item {
  value: string;
  label: string;
}

interface Props {
  items: Array<Item>;
  onRemove?: (item: Item) => void;
}

const MultiselectLabels = ({ items, onRemove }: Props) => {
  const onClick = (e: MouseEvent, item: Item) => {
    e.preventDefault();
    e.stopPropagation();
    if (onRemove) onRemove(item);
  };
  return (
    <Style>
      {items.map((item) => (
        <div key={item.value}>
          <span>{item.label}</span>
          <button
            className="icon icon-navigate-close"
            title="Close"
            onMouseDown={(e: MouseEvent) => onClick(e, item)}
          />
        </div>
      ))}
    </Style>
  );
};

const Style = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0.2rem;
  gap: 0.2rem;

  > * {
    background: var(--selected-back);
    color: var(--drop-selected-color);
    border-radius: 6px;
    font-size: 1rem;
    line-height: 1.25rem;
    padding: 0.5rem 0.5rem 0.5rem 0.8rem;

    span {
      display: inline-block;
      vertical-align: top;
    }

    .icon {
      display: inline-block;
      vertical-align: top;
      margin-left: 0.5rem;
      width: 1.25rem;
      height: 1.25rem;
      color: var(--drop-selected-color);
      background: none;
      border: none;
      cursor: pointer;
      &:hover {
        color: var(--drop-selected-color);
      }
    }
  }
`;

export default MultiselectLabels;
