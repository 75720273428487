import React from 'react';
import styled from 'styled-components';
import ItemProps from './types/ItemProps';
import TextItem from './types/TextItem';

interface Props extends ItemProps {
  item: TextItem;
}

interface StyleProps {
  Selected: boolean;
  Highlighted: boolean;
}

const TextItemTemplate: React.FC<Props> = ({
  item,
  selected = false,
  highlighted = false,
  preview = false,
  onClick,
}) => {
  return (
    <Style onClick={onClick} Selected={selected} Highlighted={highlighted}>
      {item?.icon && <span className={`icon icon-${item.icon}`} />}
      <div>{item?.label} &nbsp;</div>
      {item?.right && <div>{item.right}</div>}
    </Style>
  );
};

export default TextItemTemplate;

const Style = styled.div<StyleProps>`
  display: flex;
  padding: 0.5rem;
  line-height: 1.25em;
  height: 1.25em;
  font-size: 0.9rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  border-radius: 5px;
  border: ${(props) => getBorder};

  background-color: var(${({ Selected }) => (Selected ? '--drop-selected-back' : '--drop-item-back')});
  color: var(${({ Selected }) => (Selected ? '--drop-selected-color' : '--drop-item-color')});

  &:hover {
    background-color: ${(props) => (props.Selected ? '' : 'var(--drop-hover-back)')};
    color: ${(props) => (props.Selected ? '' : 'var(--drop-hover-color)')};
  }

  .icon {
    display: inline-block;
    vertical-align: middle;
    margin-right: 0.6rem;
    flex: 0;
    min-width: 1.25em;
  }

  div {
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
  }

  div:nth-of-type(2) {
    text-align: right;
    opacity: 0.5;
  }
`;

const getBorder = ({ Highlighted, Selected }: StyleProps): string => {
  if (Highlighted) {
    if (Selected) {
      return '2px solid var(--drop-hover-back)';
    } else {
      return '2px solid var(--drop-selected-back)';
    }
  } else {
    return '2px solid Transparent';
  }
};
