import { useEffect, useState } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";
import styled from "styled-components";
import { Switch, Route, Redirect, useParams, useRouteMatch } from "react-router-dom";
import { installBot } from "../../data/DiscordAuth";
import GuildMenu from "./components/GuildMenu";
import VerifyPanel from "./components/Verify";
import ChannelsPanel from "./components/Channels";

interface Params {
  guild: string;
}

const ManageGuild = () => {
  const [guild, setGuild] = useState<any>();
  const args = useParams<Params>();
  const { path, url } = useRouteMatch();

  useEffect(() => {
    const discordGuildDetails = httpsCallable(getFunctions(), "discordGuildDetails");
    discordGuildDetails(args).then((result: any) => {
      if (!result.data) {
        installBot(535193480273, args.guild);
      } else {
        setGuild(result.data);
        console.log(JSON.stringify(result.data.roles, null, 2));
      }
    });
    // eslint-disable-next-line
  }, []);

  return (
    <Style>
      <GuildMenu guild={guild} />
      <div>
        <Switch>
          <Route exact path={path}>
            <Redirect to={`${url}/dashboard`} />
          </Route>
          <Route path={`${path}/dashboard`}>
            <h1>Dashboard</h1>
          </Route>
          <Route path={`${path}/verify`} children={<VerifyPanel guild={guild} />} />
          <Route path={`${path}/channels`} children={<ChannelsPanel guild={guild} />} />
        </Switch>
      </div>
    </Style>
  );
};

const Style = styled.div`
  display: flex;

  > div:last-child {
    padding: 40px;
    flex: 1;
    background: #3a3f58;
    color: #eee;
    margin: 20px 20px 20px 410px;
    border-radius: 16px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
    box-sizing: border-box;
    min-height: calc(100vh - 111px);

    h1 {
      margin: 0 0 20px 0;
    }

    h2 {
      margin: 30px 0 10px 0;
    }
  }

  h1 {
    margin: 0;
  }
`;

export default ManageGuild;
