import { getDatabase, ref as dbRef, set, update } from "firebase/database";
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage";
import ICommand from "../interfaces/ICommand";

const commandRoot = `games/warframe/commands`;

const saveCommand = async (
  command: ICommand,
  originalID?: string,
  originalSubGroupID?: string,
  originalSubnameID?: string,
): Promise<void> => {
  console.log(command);
  let newpath: string;
  let oldpath: string;
  const updates: any = {};
  const deletes: any = {};

  if (command.subgroups) {
    const group = Object.values(command.subgroups)[0];
    const sub = Object.values(group.subcommands)[0];
    newpath = `/${command.id}/subgroups/${group.id}/subcommands/${sub.id}`;

    updates[`${commandRoot}/${command.id}/id`] = command.id;
    updates[`${commandRoot}/${command.id}/name`] = command.name;
    updates[`${commandRoot}/${command.id}/subgroups/${group.id}/id`] = group.id;
    updates[`${commandRoot}/${command.id}/subgroups/${group.id}/name`] = group.name;
    updates[`${commandRoot}/${newpath}`] = sub;
  } else if (command.subcommands) {
    const sub = Object.values(command.subcommands)[0];
    newpath = `/${command.id}/subcommands/${sub.id}`;
    updates[`${commandRoot}/${command.id}/id`] = command.id;
    updates[`${commandRoot}/${command.id}/name`] = command.name;
    updates[`${commandRoot}/${newpath}`] = sub;
  } else {
    newpath = `/${command.id}`;
    updates[`${commandRoot}/${newpath}`] = command;
  }

  if (originalID) {
    if (originalSubGroupID) {
      oldpath = `/${originalID}/subgroups/${originalSubGroupID}/subcommands/${originalSubnameID}`;
    } else if (originalSubnameID) {
      oldpath = `/${originalID}/subcommands/${originalSubnameID}`;
    } else {
      oldpath = `/${originalID}`;
    }

    if (oldpath !== newpath) {
      //delete the old path
      deletes[`${commandRoot}/${oldpath}`] = null;
    }
  }

  if (Object.keys(deletes).length > 0) {
    update(dbRef(getDatabase()), { ...deletes, ...updates });
  } else {
    update(dbRef(getDatabase()), updates);
  }
};

const deleteCommand = (command: string, subcommand?: string, subgroup?: string): Promise<void> => {
  if (subgroup) {
    return set(dbRef(getDatabase(), `${commandRoot}/${command}/subgroups/${subgroup}/subcommands/${subcommand}`), null);
  } else if (subcommand) {
    return set(dbRef(getDatabase(), `${commandRoot}/${command}/subcommands/${subcommand}`), null);
  } else {
    return set(dbRef(getDatabase(), `${commandRoot}/${command}`), null);
  }
};

const saveFile = async (file: File, key: string): Promise<string> => {
  return uploadBytes(storageRef(getStorage(), key), file).then((snapshot) => getDownloadURL(snapshot.ref));
};
/*
interface Persist {
  saveCommand: (command: ICommand, originalID?: string, originalSubnameID?: string) => Promise<void>;
  deleteCommand: (command: string, subcommand?: string) => Promise<void>;
  saveFile: (file: File, key: string) => Promise<string>;
}
*/
const persist = {
  saveCommand,
  deleteCommand,
  saveFile,
};

export default persist;
