import React from "react";
import DropDown from "../../../components/DropDown";

interface Props {
  value: string;
  createText?: string;
  channels: Array<Channel>;
  onChange: (value: string) => void;
  onCreate?: () => void;
}

interface Channel {
  id: string;
  type: number;
  name: string;
  parent_id: string | null;
  position: number;
}

const ChannelDropDown: React.FC<Props> = ({ value, channels, createText, onChange, onCreate }) => {
  const items = channels
    ?.filter((c) => c.type === 0 || c.type === 5)
    .map((c) => {
      const parent = channels.find((p) => p.id === c.parent_id);
      const position = c.position + (parent?.position ?? 0 * 1000);

      return {
        value: c.id,
        label: `#${c.name}`,
        position: position,
        right: c.parent_id ? channels.find((p) => p.id === c.parent_id)?.name : undefined,
      };
    })
    .sort((a: any, b: any) => a.position - b.position);

  return (
    <DropDown
      value={value}
      placeholder="Search for Channel..."
      createText={createText}
      items={items}
      onChange={onChange}
      onCreateItem={onCreate}
      itemFilter={(item, val) => item.label.toLowerCase().indexOf(val.toLowerCase()) >= 0}
    />
  );
};

export default ChannelDropDown;
