import { useEffect, useRef } from "react";
import styled from "styled-components";

interface Props {
  value?: string | File;
  width: string;
  height: string;
  error?: string;
  placeholder?: string;
  maxLength?: number;
  onChange: (value: File) => void;
}

interface StyleProps {
  Width: string;
  Height: string;
}

const ImageInput = ({ value = "/artimus.png", width, height, error, placeholder, onChange }: Props) => {
  const ref = useRef<HTMLDivElement>();
  useEffect(() => {
    if (!ref.current) return;
    if (value instanceof File) {
      const reader = new FileReader();
      const elem = ref.current;
      reader.addEventListener("load", (e: any) => (elem.style.backgroundImage = `url("${e.target.result}")`), {
        once: true,
      });
      reader.readAsDataURL(value);
    } else {
      ref.current.style.backgroundImage = `url("${value}")`;
    }
  }, [value]);

  return (
    <Style
      ref={ref as any}
      Width={width}
      Height={height}
      data-error={error}
      onClick={(e: any) => onClick(e.target, onChange)}
    >
      <div>{placeholder}</div>
    </Style>
  );
};

const Style = styled.div<StyleProps>`
  position: relative;
  width: ${(p) => p.Width};
  height: ${(p) => p.Height};
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  border-radius: 30%;
  box-shadow: 0 0 2px rgba(0, 0, 0);
  display: flex;
  cursor: pointer;

  align-items: center;
  align-content: center;

  &[data-error] {
    border-color: red;
  }

  div {
    flex: 1;
    color: white;
    font-weight: bold;
    transition: opacity 0.5s;
    text-align: center;
    position: relative;
    opacity: 0;
    font-size: 0.9rem;
    line-height: 1.2rem;
    transition: opacity 0.35s;
    pointer-events: none;
  }

  &::before {
    position: absolute;
    inset: 0;
    background: rgba(0, 0, 0, 0.8);
    opacity: 0;
    transition: opacity 0.35s;
    content: "";
  }

  &:not(.select):hover {
    &::before,
    div {
      opacity: 1;
    }
  }
`;

const onClick = (elem: HTMLInputElement, onChange: (value: File) => void) => {
  elem.classList.add("select");

  setTimeout(() => {
    //select file
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accpet", ".png|.jpg|.jpeg|image/*");
    input.click();

    const onChangeInternal = (e: any) => {
      // @ts-ignore
      if (input.files.length > 0) onChange(input.files[0]);
    };

    input.addEventListener("change", onChangeInternal);

    const onLeave = () => {
      setTimeout(() => {
        elem.classList.remove("select");
        input.removeEventListener("change", onChangeInternal);
      }, 350);
    };
    window.addEventListener("focus", onLeave, { once: true });
  }, 350);
};

export default ImageInput;
