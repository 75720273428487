import React, { useState, useRef, MouseEvent } from 'react';
import styled from 'styled-components';

import DropDownPanel from './DropDownPanel';
import ItemList from './ItemList';
import TextItemTemplate from './TextItem';
import SearchInput from './SearchInput';
import MultiselectLabels from './MultiselectLabels';

interface Props {
  value: string;
  multiselect?: boolean;
  deselect?: boolean;
  loading?: boolean;
  items: Array<any>;
  itemTemplate?: any;
  itemFilter?: (item: any, value: string) => boolean;
  itemValueProperty?: string;
  itemLabelProperty?: string;
  createText?: string;
  createItem?: any;
  onChange: (value: string) => void;
  onCreateItem?: () => void;
  placeholder?: string;
  onFilterChange?: (value: string) => void;
  visibleItems?: number;
  validateValue?: boolean;
  arrow?: boolean;
  readonly?: boolean;
}

interface StyleProps {
  Focus: boolean;
  ReadOnly: boolean;
  Filter: boolean;
}

const DropDown: React.FC<Props> = ({
  value = '',
  multiselect = false,
  deselect = false,
  loading = false,
  items = [],
  itemTemplate,
  itemFilter = (item, value) => item.value && `${item.value}`.toLowerCase().indexOf(value.toLowerCase()) >= 0,
  itemValueProperty = 'value',
  itemLabelProperty = 'label',
  createText,
  createItem,
  onChange = () => {},
  onCreateItem,
  placeholder,
  onFilterChange,
  visibleItems = 5,
  validateValue = true,
  arrow = true,
  readonly = false,
}) => {
  if (!itemTemplate) {
    itemTemplate = TextItemTemplate;
    items = items.map((i) => ({
      label: i[itemLabelProperty],
      value: i[itemValueProperty],
      right: i.right,
    }));

    itemValueProperty = 'value';
  }

  if (!items.some((item) => typeof item !== 'string')) {
    items = items.map((val) => ({
      label: val,
      value: val,
    }));
  }

  if (validateValue && !loading) {
    let changed = false;
    if (!multiselect && value.length > 0 && items.findIndex((item) => item[itemValueProperty] === value) < 0) {
      value = '';
      changed = true;
    }

    if (!(deselect || multiselect || placeholder) && value.length === 0 && items.length > 0 && !createItem) {
      value = items[0][itemValueProperty];
      changed = true;
    }

    if (changed) {
      setTimeout(() => onChange(value), 40);
    }
  }

  if (createText && createItem && !onCreateItem) {
    onCreateItem = () => onChange('');
  }

  const ref = useRef<HTMLDivElement>(null);
  const [expanded, setExpanded] = useState(false);
  const [focus, setFocus] = useState(false);
  const [filter, setFilter] = useState('');
  const [cursorPosition, setCursorPosition] = useState(-1);

  let panel;
  let onKeyDown;
  let values: Array<any>;

  if (value?.length > 0) {
    try {
      values = JSON.parse(value);
    } catch (e) {
      values = value.split(',').map((v) => ({ label: v, value: v }));
    }
  } else {
    values = [];
  }

  if (expanded) {
    const filteredItems =
      onFilterChange || filter.length === 0 ? items : items.filter((item) => itemFilter(item, filter));

    let data: Array<any> = [];

    onKeyDown = (e: KeyboardEvent) => {
      let move = 0;
      switch (e.key) {
        case 'Down': // IE/Edge specific value
        case 'ArrowDown':
          move = 1;
          break;
        case 'Up': // IE/Edge specific value
        case 'ArrowUp':
          move = -1;
          break;
        case 'Escape':
          if (filter.length > 0) {
            setFilter('');
            setCursorPosition(-1);
          } else {
            setExpanded(false);
          }
          return;
        case 'Enter':
          if (cursorPosition >= 0) {
            let item = filteredItems[cursorPosition];
            onItemSelect(item, item[itemValueProperty]);
          }
          break;
        default:
          return;
      }

      e.stopPropagation();
      e.preventDefault();

      if (move !== 0) {
        let position = cursorPosition >= 0 ? cursorPosition : data.findIndex((d) => d.selected);
        if (position < 0) {
          position = 0;
        } else {
          position += move;
        }

        if (position < 0) position = data.length - 1;
        if (position >= data.length) position = 0;

        setCursorPosition(position);
      }
    };

    const onItemSelect = (item: any, newvalue: string) => {
      if (multiselect) {
        if (values.find((v) => v.value === newvalue)) {
          values = values.filter((v) => v.value !== newvalue);
        } else {
          values.push({
            value: newvalue,
            label: item[itemLabelProperty],
          });
        }
        onChange(JSON.stringify(values));
      } else if (value !== newvalue) {
        onChange(newvalue);
        setFilter('');
        setExpanded(false);
      } else if (deselect) {
        onChange('');
      } else {
        setExpanded(false);
      }
    };

    if (filteredItems.length > 0) {
      data = filteredItems.map((item, i) => {
        let itemvalue = item[itemValueProperty];

        return {
          selected: (multiselect && values.find((v) => v.value === itemvalue)) || (!multiselect && value === itemvalue),
          highlighted: cursorPosition === i,
          value: itemvalue,
          data: item,
        };
      });

      panel = (
        <DropDownPanel parent={ref}>
          <ItemList
            items={data}
            itemTemplate={itemTemplate}
            itemClick={onItemSelect}
            visibleItems={visibleItems}
            highlightIndex={cursorPosition}
          />
          {createText && <div />}
          {createText && (
            <TextItemTemplate
              item={{ label: createText, value: '' }}
              onClick={() => {
                if (onCreateItem) onCreateItem();
                setExpanded(false);
              }}
            />
          )}
        </DropDownPanel>
      );
    }
  } else {
    onKeyDown = (e: KeyboardEvent) => {
      setExpanded(true);
    };
  }

  const onDeselectItem = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onChange('');
    (e.target as HTMLElement)?.parentNode?.querySelector('input')?.focus();
    setExpanded(true);
  };

  const Template = itemTemplate;
  const selectedItem =
    value === '' && createItem ? createItem : items.find((item) => item[itemValueProperty] === value);
  const showSearch = multiselect || (focus && filter.length > 0) || !selectedItem;

  const search = (
    <SearchInput
      value={filter}
      visible={showSearch && !loading}
      placeholder={placeholder}
      onFocus={(e) => {
        setFocus(true);
        setExpanded(true);
      }}
      onBlur={(e) => {
        setFocus(false);
        setExpanded(false);
        setCursorPosition(-1);
      }}
      onChange={(value) => {
        setFilter(value);
        if (onFilterChange) onFilterChange(value);
        setCursorPosition(-1);
      }}
      onKeyDown={onKeyDown}
    />
  );

  let multiselectItems;
  if (multiselect) {
    multiselectItems = (
      <MultiSelectContainer>
        {values.length > 0 && (
          <MultiselectLabels
            items={values}
            onRemove={(item) => onChange(JSON.stringify(values.filter((v) => v.value !== item.value)))}
          />
        )}
        {search}
      </MultiSelectContainer>
    );
  }

  if (!value) value = '';

  return (
    <Style ref={ref} Focus={focus} ReadOnly={readonly} Filter={!multiselect && showSearch}>
      <div
        onClick={(e) => {
          if (readonly) return;
          ref.current?.querySelector('input')?.focus();
          setExpanded(true);
        }}
      >
        {multiselect ? multiselectItems : <Template item={selectedItem} preview={true} />}
        {`${value}`.length > 0 && deselect && (
          <button className="icon icon-status-failure" onMouseDown={onDeselectItem} />
        )}
        {arrow && <div className="icon icon-chevron-down-light" />}
        {!multiselect && search}
      </div>
      {panel}
    </Style>
  );
};

const MultiSelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  > :first-child {
    flex: 1;
  }
  > :last-child {
    flex: 0;
  }
`;

const Style = styled.div<StyleProps>`
  &:where(*) {
    --selected-back: var(--dropdown-selected, #106ebe);
    --drop-selected-color: white;
    --border-color: var(--dropdown-border, #666);
    --drop-hover-back: var(--dropdown-background, #deecf9);
    --drop-hover-color: var(--dropdown-hover-color, #000);
    --drop-background: var(--dropdown-background, #fff);
    --drop-readonly-background: var(--dropdown-background, #ddd);
    --drop-color: var(--dropdown-color, #000);
  }

  position: relative;
  flex-direction: column;
  border-radius: 0.5rem;
  background-color: var(${({ ReadOnly }) => (ReadOnly ? '--drop-readonly-background' : '--drop-background')});
  box-sizing: border-box;
  border: 0.1rem solid var(${({ Focus }) => (Focus ? '--selected-back' : '--border-color')});
  margin: 0;
  overflow: hidden;
  color: var(--drop-color);
  pointer-events: ${({ ReadOnly }) => (ReadOnly ? 'none' : 'inherit')};
  text-align: left;

  &:hover {
    background-color: var(--drop-hover-back);
  }

  button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    margin: 0;

    color: var(--drop-color);
    &:hover {
      color: var(--drop-hover-color);
    }
  }

  > div {
    display: flex;
  }

  > div > .icon {
    min-width: 2rem;
    flex: 0;
  }

  > div > :not(.icon) {
    flex: 1;
    font-size: 0.9rem !important;
  }

  > div > :not(:last-child, div.icon) {
    opacity: ${({ Filter }) => (Filter ? 0 : 1)};
    pointer-events: ${({ Filter }) => (Filter ? 'none' : 'inherit')};
  }

  > div > div:last-child:not(.icon) {
    position: absolute;
    top: 0;
    left: 0;
    right: 2rem;
    bottom: 0;
  }
`;

export default DropDown;
