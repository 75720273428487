import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import ErrorInput from "../../components/ErrorInput";

interface Props {
  choices: Array<string>;
  onChange: (value: string, index: number) => void;
  onClose: () => void;
}

const ChoicesBalloon = ({ choices, onChange, onClose }: Props) => {
  const rows = [...choices, ""];
  const ref = useRef<HTMLDivElement>();

  useEffect(() => {
    ref.current?.querySelector("input")?.focus();
  }, []);

  return (
    <Style>
      <div onClick={() => onClose()} />
      <div ref={ref as any}>
        <h3>Choices</h3>
        <div>
          {rows.map((v, i) => (
            <ErrorInput key={i} value={v} placeholder="Choice" onChange={(val) => onChange(val, i)} />
          ))}
        </div>
        <button onClick={() => onClose()}>OK</button>
      </div>
    </Style>
  );
};

const Style = styled.div`
  z-index: 1;
  position: absolute;
  right: 120px;
  top: 50%;
  height: 0;

  &::after {
    z-index: 4;
    position: absolute;
    border: 10px solid #3a3f58;
    transform: rotate(45deg) translate(-16px, 0px);
    box-shadow: 3px 0px 2px rgba(0, 0, 0, 0.5);
    border-left-color: transparent;
    border-bottom-color: transparent;
    content: "";
  }

  h3 {
    margin: 0 0 1rem 0;
    font-weight: normal;
  }

  button {
    font-size: 0.9rem;
    margin-top: 0.5rem;
    padding: 0.4rem 1rem;
  }

  > div:first-child {
    z-index: 2;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: black;
    opacity: 0.1;
  }

  > div:last-child {
    z-index: 3;
    position: absolute;
    top: 0;
    right: 0;
    width: 330px;
    min-height: 50px;
    background: #3a3f58;
    border-radius: 10px;
    transform: translateY(-50%);
    padding: 1rem 0.7rem 1rem 1rem;
    box-sizing: border-box;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
    border: 1px solid #292d3e;

    > div {
      z-index: 5;
      max-height: 250px;
      overflow: auto;
      padding-right: 0.8rem;

      > div {
        margin-bottom: 0.4rem;
      }
    }
  }
`;

export default ChoicesBalloon;
