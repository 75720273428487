import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { IGuildChannel } from '../../../interfaces/IGuildChannelConfig';
import ChannelDropDown from './ChannelDropDown';
import Input from '../../../components/ErrorInput';

interface Props {
  platform: string;
  config: IGuildChannel;
  guild: any;
  focus?: boolean;
  onNameChange: (val?: string) => void;
  onIdChange: (val?: string) => void;
}

const ChannelCreateToggle: React.FC<Props> = ({ platform, config, guild, focus, onNameChange, onIdChange }) => {
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (ref.current && focus) ref.current.focus();
  }, [focus]);

  return (
    <Style>
      <span>{platform.toUpperCase()} :</span>
      {typeof config.name !== 'undefined' ? (
        <>
          <Input
            ref={ref}
            value={config.name}
            placeholder="Channel Name"
            onChange={(val: string) => onNameChange(val.replace(/\s+/g, '-'))}
          />
          <div
            className="icon icon-search"
            onClick={() => {
              onNameChange(undefined);
              setTimeout(() => {
                const elem = window.document.querySelector(`input[type="text"]`);
                if (elem) (elem as HTMLInputElement).focus();
              }, 100);
            }}
          />
        </>
      ) : (
        <ChannelDropDown
          value={config.id ?? ''}
          channels={guild.channels}
          onChange={(val: string) => onIdChange(val)}
          createText="New Channel"
          onCreate={() => {
            onNameChange('');
            setTimeout(() => ref.current && ref.current.focus(), 100);
          }}
        />
      )}
    </Style>
  );
};

const Style = styled.section`
  display: flex;
  margin: 4px 0;

  > *:nth-child(1) {
    display: block;
    width: 80px;
    line-height: 41px;
  }

  > *:nth-child(2) {
    flex: 1;
    max-width: 400px;
  }

  > .icon {
    margin-left: 10px;
    min-width: 40px;
    cursor: pointer;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.1);
    color: #757ca1;

    &:hover {
      background: rgba(0, 0, 0, 0.25);
    }
  }

  input {
    font-size: 0.9rem;
    line-height: 1.2rem;
    margin-bottom: 1px;
  }
`;

export default ChannelCreateToggle;
