import React from "react";
import styled from "styled-components";
import VerificationRoles from "./VerificationRoles";
import VerificationMessage from "./VerificationMessage";

interface Props {
  guild: any;
}

const VerifyPanel: React.FC<Props> = ({ guild }) => {
  return (
    <Style>
      <h1>User Verification</h1>
      <p>
        Once a user verifies their in game name, the bot can set their nickname on your server and assign roles based on
        the platform they verified.
      </p>
      <div>
        <VerificationRoles guild={guild} />
        <VerificationMessage guild={guild} />
      </div>
    </Style>
  );
};

const Style = styled.div`
  > div {
    display: flex;
    flex-direction: columns;
    flex-wrap: wrap;

    > div {
      &:first-child {
        min-width: 100%;
      }
      &:nth-child(2) {
        margin-right: 40px;
      }
      &:last-child {
        h2 {
          margin-bottom: 14px;
        }
        textarea {
          height: 182px;
        }
        min-width: 500px;
      }
    }
  }

  tr > *:first-child {
    width: 150px;
  }

  tr > *:last-child {
    width: 250px;
  }

  td {
    padding: 2px 0;
  }

  > div > div > div > button {
    margin-top: 20px;
  }
`;

export default VerifyPanel;
