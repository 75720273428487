import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getPerformance } from "firebase/performance";
import { getAuth, onAuthStateChanged, signInWithCustomToken, ParsedToken } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import { discordLogin } from "./data/DiscordAuth";

import AdminRoute from "./routes/AdminRoute";
import UserRoute from "./routes/UserRoute";
import ScrollToTop from "./routes/ScrollToTop";

import Blog from "./pages/blog/Blog";
import Ophy from "./pages/ophy";
import Login from "./pages/login";
import OAuth from "./pages/oauth";
import ChooseGuild from "./pages/guilds/ChooseGuild";
import ManageGuild from "./pages/guilds/ManageGuild";
import Commands from "./pages/commands";
import MenuBar from "./components/MenuBar";

const firebaseConfig = {
  apiKey: "AIzaSyAe037IEI5tQsiamsJ70zlzhvCWoclUPpY",
  authDomain: "relicbot-325715.firebaseapp.com",
  databaseURL: "https://relicbot-325715-default-rtdb.firebaseio.com",
  projectId: "relicbot-325715",
  storageBucket: "relicbot-325715.appspot.com",
  messagingSenderId: "106799283811",
  appId: "1:106799283811:web:092efbe5e35add65b70256",
  measurementId: "G-LC2SVSZF8R",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

getPerformance(app);
getAnalytics(app);

function App() {
  const [ready, setReady] = useState<string>();
  const [user, setUser] = useState<ParsedToken | null>();

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        user.getIdTokenResult().then((token) => setUser(token.claims));
      } else if (window.location.hostname === "localhost") {
        const functions = getFunctions();
        const localUser = httpsCallable(functions, "localUser");
        localUser().then((result) => {
          const token = result.data as string;
          signInWithCustomToken(getAuth(), token);
        });
      } else if (window.location.pathname === "/oauth") {
        setReady("oauth");
      } else {
        discordLogin();
      }
    });
  }, []);

  if (!user && !ready) {
    return <></>;
  }

  return (
    <>
      <Router>
        <ScrollToTop />
        <Switch>
          <Route path="/oauth" children={<OAuth />} />
          <AdminRoute
            exact
            path={[
              "/admin/commands",
              "/admin/commands/:command",
              "/admin/commands/:command/:sub",
              "/admin/commands/:command/:group/:sub",
            ]}
            user={user}
            children={<Commands />}
          />
          <UserRoute exact path="/manage" user={user} children={<ChooseGuild />} />
          <UserRoute path="/bot" user={user} children={<Blog />} />
          <UserRoute path="/manage/:guild" user={user} children={<ManageGuild />} />
          <Route path="/login" children={<Login />} />
          <Route path="/" children={<Ophy />} />
        </Switch>

        <MenuBar user={user} />
      </Router>
    </>
  );
}

export default App;
