import React from 'react';
import styled from 'styled-components';

interface Props {
  checked: boolean;
  onChange: (value: boolean) => void;
}

const Slider: React.FC<Props> = ({ checked, onChange }) => {
  return (
    <Style onClick={(e) => onChange(!checked)}>
      <input type="checkbox" checked={checked} onChange={() => {}} />
      <span />
    </Style>
  );
};

const Style = styled.div`
  display: inline-block;
  position: relative;
  width: 50px;
  min-width: 50px;
  height: 24px;
  margin: 3px 10px 3px 5px;
  vertical-align: middle;

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + span {
      background-color: var(--highlight, #093);
      &:before {
        transform: translateX(26px);
      }
    }
  }

  span {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(136, 135, 135);
    transition: 0.3s;
    border-radius: 34px;

    &:before {
      position: absolute;
      content: '';
      height: 20px;
      width: 20px;
      left: 2px;
      bottom: 2px;
      background-color: white;
      transition: 0.4s;
      border-radius: 50%;
    }
  }
`;

export default Slider;
