const insertAnchors = (html: string): string => {
  let newHtml = html;
  let reg = /(<h[2-5][^>]*>(.+?)(?=<\/h[1-5]>))(<\/h[2-5]>)/gim;
  let result;
  while ((result = reg.exec(html)) !== null) {
    const replaced = `${result[1]}${getAnchor(result[2])}${result[3]}`;
    newHtml = newHtml.replace(result[0], replaced);
  }
  return newHtml;
};

const innerText = (val: string): string => {
  return val
    .replace(/<[/\w][^>]+>/gi, "")
    .replace(/&amp;/g, "&")
    .trim();
};

const getAnchor = (html: string): string => {
  const title = innerText(html);
  const anchor = title.toLowerCase().replace(/\s+/g, "-");
  return `<a id="${anchor}"></a>`;
};
export default insertAnchors;
