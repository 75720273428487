import ICommand, { ILanguage, IOption, ISubCommand, ISubGroup } from "../interfaces/ICommand";
import ICommandEdit, { IOptionEdit, ILanguageEdit } from "../interfaces/ICommandEdit";

export const convertToCommandEdit = (command?: ICommand, subcommand?: string, subgroup?: string): ICommandEdit => {
  if (!command) {
    return {
      name: { en: { value: "" } },
      command: { en: { value: "" } },
      subgroup: { en: { value: "" } },
      subcommand: { en: { value: "" } },
      description: { en: { value: "" } },
      options: [],
      changed: false,
    };
  }

  const name: ILanguageEdit = {};

  if (!subcommand && !subgroup) {
    Object.entries(command.name).forEach(([key, value]) => {
      name[key] = { value: `/${value.value}`, auto: value.auto };
    });

    return {
      name: name,
      command: editLanguge(command.name),
      subgroup: {},
      subcommand: {},
      description: editLanguge(command.description),
      image: command.image,
      options: editOptions(command?.options),
      changed: false,
    };
  }

  let sub: ISubCommand;
  let group: ISubGroup | null = null;

  if (subgroup) {
    if (!subcommand) throw new Error("Sub groups must specify a subcommand.");
    if (!command.subgroups) throw new Error("This command does not have sub group.");
    group = command.subgroups[subgroup];
    if (!group) throw new Error("Did not find sub group");
    sub = group.subcommands[subcommand];
    if (!sub) throw new Error("Did not find sub command");
    Object.entries(sub.name).forEach(([key, value]) => {
      name[key] = { value: `/${command.name[key].value} ${group?.name[key].value} ${value.value}` };
    });
  } else if (subcommand) {
    if (!command.subcommands) throw new Error("This command does not have sub commands.");
    sub = command.subcommands[subcommand];
    if (!sub) throw new Error("Did not find sub command");
    Object.entries(sub.name).forEach(([key, value]) => {
      name[key] = { value: `/${command.name[key].value} ${value.value}` };
    });
  } else {
    throw new Error("This should never happen.");
  }

  return {
    name: name,
    command: editLanguge(command.name),
    subgroup: group ? editLanguge(group.name) : {},
    subcommand: editLanguge(sub.name),
    description: editLanguge(sub.description),
    image: sub.image ?? command.image,
    options: editOptions(sub.options),
    changed: false,
  };
};

const editOptions = (src: Array<IOption> | undefined): Array<IOptionEdit> => {
  return (
    src?.map((o) => ({
      type: o.type,
      name: editLanguge(o.name),
      title: editLanguge(o.title),
      description: editLanguge(o.description),
      required: o.required,
      choices: o.choices?.map((choice) => editLanguge(choice)) ?? [],
    })) ?? []
  );
};

const editLanguge = (field?: ILanguage): ILanguageEdit => {
  return field ? { ...field } : {};
};

export const convertToCommand = (command: ICommandEdit): ICommand => {
  const commandID: string = command.command.en.value;
  const subCommandID: string = command.subcommand?.en?.value;
  const subGroupID: string = command.subgroup?.en?.value;

  let retCommand: ICommand = {
    id: commandID,
    name: convertLanguage(command.command),
  };
  let newcmd: ICommand;

  if (subCommandID) {
    newcmd = {
      id: subCommandID,
      name: convertLanguage(command.subcommand),
    };

    if (subGroupID) {
      retCommand.subgroups = {
        [subGroupID]: {
          id: subGroupID,
          name: convertLanguage(command.subgroup),
          subcommands: { [subCommandID]: newcmd as ISubCommand },
        },
      };
    } else {
      retCommand.subcommands = { [subCommandID]: newcmd as ISubCommand };
    }
  } else {
    newcmd = retCommand;
  }

  newcmd.description = convertLanguage(command.description);

  if (typeof command.image === "string") {
    newcmd.image = command.image;
  }

  if (command.options.length > 0) {
    newcmd.options = convertOptions(command.options);
  }

  return retCommand;
};

const convertLanguage = (field: ILanguageEdit): ILanguage => {
  const language: ILanguage = {};
  Object.entries(field).forEach(([key, lang]) => {
    const newlang: any = {
      value: lang.value,
    };
    if (lang.auto) newlang.auto = true;
    language[key] = newlang;
  });
  return language;
};

const convertOptions = (options: Array<IOptionEdit>): Array<IOption> | undefined => {
  if (options.length === 0) return undefined;
  return options.map((o: IOptionEdit): IOption => {
    const option: IOption = {
      type: o.type,
      name: convertLanguage(o.name),
      title: convertLanguage(o.title),
      description: convertLanguage(o.description),
      required: o.required,
    };

    if (o.choices.length > 0) {
      option.choices = convertChoices(o.choices);
    }

    return option;
  });
};

const convertChoices = (choices: Array<ILanguageEdit>): Array<ILanguage> | undefined => {
  if (choices.length === 0) return undefined;
  return choices.map((c) => convertLanguage(c));
};
