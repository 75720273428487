import IGuildChannelConfig, { IGuildChannel, IGuildChannelPlatform } from "../interfaces/IGuildChannelConfig";

interface InitAction {
  type: "init";
  payload: IGuildChannelConfig;
}

interface Action {
  platform: "pc" | "xbox" | "psn" | "nsw";
}

interface PlatformAction extends Action {
  type: "platform";
  field: "id" | "name";
  value: string;
}

interface ChannelAction extends Action {
  type: "channel";
  action: "update" | "delete" | "create" | "init";
  index?: number;
  field?: "id" | "name";
  value?: string;
  payload?: IGuildChannel;
}

interface ChannelCommandAction extends Action {
  type: "command";
  index: number;
  command: string;
  value: boolean;
}

const CommandReducer = (state: IGuildChannel, action: ChannelCommandAction): IGuildChannel => {
  return { ...state, commands: { ...state.commands, [action.command]: action.value } };
};

const ChannelReducer = (
  state: IGuildChannelPlatform,
  action: ChannelAction | ChannelCommandAction,
): IGuildChannelPlatform => {
  if (action.type === "channel") {
    switch (action.action) {
      case "create":
        return { ...state, channels: [...state.channels, { name: "", commands: {} }] };
      case "delete":
        return { ...state, channels: state.channels.filter((c, i) => i !== action.index) };
      case "init":
        if (typeof action.index === "undefined" || !action.payload) {
          return state;
        } else {
          const data = { ...state, channels: [...state.channels] };
          data.channels[action.index] = action.payload;
          return data;
        }
    }
  }

  if (typeof action.index === "undefined") {
    return state;
  }

  const newstate = { ...state, channels: [...state.channels] };
  let channel = state.channels[action.index];

  if (action.type === "command") {
    channel = CommandReducer(channel, action);
  } else {
    switch (action.field) {
      case "id":
        channel = { ...channel, id: action.value };
        break;
      case "name":
        channel = { ...channel, name: action.value };
        break;
      default:
        return state;
    }
  }

  newstate.channels[action.index] = channel;
  return newstate;
};

const PlatformReducer = (
  state: IGuildChannelPlatform,
  action: PlatformAction | ChannelAction | ChannelCommandAction,
): IGuildChannelPlatform => {
  switch (action.type) {
    case "channel":
    case "command":
      return ChannelReducer(state, action);
  }

  switch (action.field) {
    case "id":
      return { ...state, category: { id: action.value } };
    case "name":
      return { ...state, category: { name: action.value } };
    default:
      return state;
  }
};

const GuldChannelReducer = (
  state: IGuildChannelConfig,
  action: InitAction | PlatformAction | ChannelAction | ChannelCommandAction,
): IGuildChannelConfig => {
  switch (action.type) {
    case "init":
      return action.payload;
    case "platform":
    case "channel":
    case "command":
      const newstate = { ...state };
      newstate[action.platform] = PlatformReducer(newstate[action.platform], action);
      return newstate;
    default:
      return state;
  }
};

export default GuldChannelReducer;
