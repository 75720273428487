import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useCommands } from "../../../data/CommandHook";
import Slider from "../../../components/Slider";
import ICommand from "../../../interfaces/ICommand";
import { IGuildChannel } from "../../../interfaces/IGuildChannelConfig";
import ChannelCreateToggle from "./ChannelCreateToggle";

interface Props {
  configs: {
    [platform: string]: IGuildChannel;
  };
  guild: any;
  isnew: boolean;
  onChange: (field: "name" | "id" | "command", value?: string, platform?: string) => void;
  onCancel: (data: IGuildChannel) => void;
  onClose: () => void;
  onDelete: () => void;
}

interface CommandItem {
  value: string;
  label: string;
  description: string;
}

const GuildChannel: React.FC<Props> = ({ configs, guild, isnew, onChange, onClose, onDelete, onCancel }) => {
  const [original] = useState<IGuildChannel>(JSON.parse(JSON.stringify(configs)));
  const commands = getCommands(useCommands());
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (ref.current) ref.current.focus();
  }, []);

  if (Object.keys(configs).length === 0) return null;

  return (
    <Style>
      <h1>Channel Details</h1>
      <p>Choose which squads will be posted to the channel.</p>
      <h2>Channel</h2>
      {Object.entries(configs).map(([platform, config]) => (
        <ChannelCreateToggle
          key={platform}
          platform={platform}
          config={config}
          guild={guild}
          onNameChange={(name) => onChange("name", name, platform)}
          onIdChange={(id) => onChange("id", id, platform)}
        />
      ))}
      <h2>Commands</h2>
      <div>
        {commands.map((item) => (
          <div key={item.value}>
            <Slider
              checked={Object.values(configs)[0].commands[item.value] ?? false}
              onChange={() => onChange("command", item.value)}
            />
            <div>
              {item.label}
              <span>{item.description}</span>
            </div>
          </div>
        ))}
      </div>
      <button onClick={() => onClose()}>OK</button>
      <button onClick={() => (isnew ? onDelete() : onCancel(original))}>Cancel</button>
      {!isnew && <button onClick={() => onDelete()}>Delete</button>}
    </Style>
  );
};

const getCommands = (commands?: Array<ICommand>): Array<CommandItem> => {
  if (!commands) return [];

  const items: Array<CommandItem> = [];
  commands
    .filter((c) => c.id !== "profile" && c.id !== "leave")
    .forEach((c) => {
      if (c.subgroups || c.subcommands) {
        if (c.subgroups) {
          Object.values(c.subgroups).forEach((group) => {
            if (!group.subcommands) return;
            Object.values(group.subcommands).forEach((sub) => {
              items.push({
                value: `\\${c.id}\\${group.id}\\${sub.id}`,
                label: `/${c.id} ${group.id} ${sub.id}`,
                description: sub?.description?.en?.value ?? "",
              });
            });
          });
        }
        if (c.subcommands) {
          Object.values(c.subcommands).forEach((sub) => {
            items.push({
              value: `\\${c.id}\\${sub.id}`,
              label: `/${c.id} ${sub.id}`,
              description: sub?.description?.en?.value ?? "",
            });
          });
        }
      } else {
        items.push({
          value: `\\${c.id}`,
          label: `/${c.id}`,
          description: c?.description?.en?.value ?? "",
        });
      }
    });

  return items;
};

const Style = styled.div`
  > div:last-of-type {
    columns: 300px auto;
    margin-bottom: 30px;

    > div {
      display: flex;
      align-items: center;
      page-break-inside: avoid;
      break-inside: avoid;
      margin: 0 0 15px 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    span {
      display: block;
      font-size: 0.9rem;
      color: #999;
    }
  }

  button:nth-of-type(3) {
    float: right;
    background: #df6071;
  }
`;

export default GuildChannel;
