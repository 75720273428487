import React from "react";
import styled from "styled-components";
import DropDown from "../../../components/DropDown";

interface Props {
  value: string;
  createText?: string;
  roles: Array<any>;
  onChange: (value: string) => void;
  onCreate?: () => void;
}

interface Role {
  id: string;
  name: string;
  color: number;
}

interface ItemProps {
  item: Role;
  selected?: boolean;
  highlighted?: boolean;
  preview?: boolean;
  onClick: () => void;
}

interface StyleProps {
  Selected: boolean;
  Highlighted: boolean;
  Color: string;
}

const RoleDropDown: React.FC<Props> = ({ value, roles, createText, onChange, onCreate }) => {
  return (
    <DropDown
      value={value}
      placeholder="Search for Role..."
      createText={createText}
      items={roles?.filter((r: any) => r.name !== "@everyone")}
      itemTemplate={RoleItem}
      itemValueProperty="id"
      onChange={onChange}
      onCreateItem={onCreate}
      itemFilter={(item, value) => item.name.toLowerCase().indexOf(value.toLowerCase()) >= 0}
    />
  );
};

const RoleItem: React.FC<ItemProps> = ({ item, selected = false, highlighted = false, onClick }) => {
  return (
    <ItemStyle onClick={onClick} Selected={selected} Highlighted={highlighted} Color={getColor(item?.color)}>
      {item?.name}
    </ItemStyle>
  );
};

const ItemStyle = styled.div<StyleProps>`
  display: flex;
  padding: 0.5rem;
  line-height: 1.25em;
  height: 1.25em;
  font-size: 0.9rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  border-radius: 5px;
  color: ${({ Color }) => Color};
  border: ${(props) => getBorder};
  font-weight: bold;

  background-color: var(${({ Selected }) => (Selected ? "--drop-selected-back" : "--drop-item-back")});

  &:hover {
    background-color: ${(props) => (props.Selected ? "" : "var(--drop-hover-back)")};
  }

  .icon {
    display: inline-block;
    vertical-align: middle;
    margin-right: 0.6rem;
    flex: 0;
    min-width: 1.25em;
  }

  div {
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
  }

  div:nth-of-type(2) {
    text-align: right;
  }
`;

const getBorder = ({ Highlighted, Selected }: StyleProps): string => {
  if (Highlighted) {
    if (Selected) {
      return "2px solid var(--drop-hover-back)";
    } else {
      return "2px solid var(--drop-selected-back)";
    }
  } else {
    return "2px solid Transparent";
  }
};

const getColor = (val: number): string => {
  if (!val || val === 0) return "#99aab5";
  let str = `000000${val.toString(16)}`.slice(-6);
  return `#${str}`;
};

export default RoleDropDown;
