import React, { useEffect, useReducer, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import ICommand from "../../interfaces/ICommand";
import ICommandEdit, { IOptionEdit } from "../../interfaces/ICommandEdit";
import { convertToCommandEdit, convertToCommand } from "../../data/CommandConvert";
import persist from "../../data/CommandPersist";

import ErrorInput from "../../components/ErrorInput";
import ImageInput from "../../components/ImageInput";
import DropDown from "../../components/DropDown";
import CommandReducer from "../../data/CommandReducer";
import OptionRow from "./option";

// Create a root reference
interface Props {
  language: string;
  command?: ICommand;
  subgroup?: string;
  subcommand?: string;
  setLanguage: (langauge: string) => void;
}

const languages = [
  { value: "en", label: "English" },
  { value: "fr", label: "Française" },
  { value: "de", label: "Deutsch" },
  { value: "es", label: "Español" },
  { value: "pt", label: "Português" },
  { value: "ru", label: "Pусский" },
  { value: "ko", label: "한국인" },
  { value: "zh", label: "中国人" },
];

const CommandPanel = ({ language, command, subgroup, subcommand, setLanguage }: Props) => {
  const [state, dispatch] = useReducer(CommandReducer, convertToCommandEdit());
  const [translate, setTranslate] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>();
  const history = useHistory();

  useEffect(() => {
    if (!command || typeof command.name === "string" || !command.name[language]) {
      setLanguage("en");
    }
    setTranslate(false);

    try {
      dispatch({
        type: "init",
        command: convertToCommandEdit(command, subcommand, subgroup),
      });
    } catch (ex) {
      //do something later
    }
    // eslint-disable-next-line
  }, [command, subcommand]);

  let options: Array<IOptionEdit> = [...state.options];
  if (options.length < 25) {
    options.push({
      name: {},
      title: {},
      description: {},
      required: false,
      choices: [],
    });
  }

  useEffect(() => {
    //Drag / Drop Handler
    const container = ref.current;
    if (!container) return;
    const rows = [...container.querySelectorAll("[draggable]")].map((node) => node.parentNode) as Array<ParentNode>;
    if (rows && rows.length === 0) return;

    let moveRow: any;
    let firstPos: number;
    let firstRow = rows[0].previousSibling as any;
    let offset = dragPosition(rows[0]);

    const dragOver = (e: any) => {
      e.preventDefault();
      e.dataTransfer.dropEffect = "move";
    };

    const dragStart = (e: any) => {
      moveRow = e.target.parentNode;
      while (moveRow && !moveRow.hasAttribute("class")) moveRow = moveRow.parentNode;
      if (!moveRow) return;
      firstPos = dragPosition(moveRow);
      moveRow.setAttribute("dragging", true);
    };

    const dragEnter = (e: any) => {
      let target = e.target;
      while (target && target?.parentNode !== moveRow.parentNode) target = target.parentNode;
      if (!target || moveRow === e.target) return;
      const from = dragPosition(moveRow);
      const to = dragPosition(target);
      moveRow.parentNode.insertBefore(moveRow, from > to ? target : target.nextSibling);
    };
    const dragEnd = (e: any) => {
      moveRow.removeAttribute("dragging");
      const newPosition = dragPosition(moveRow);
      if (newPosition !== firstPos) {
        let lastRow = firstRow;
        rows.forEach((r) => {
          lastRow.parentNode.insertBefore(r, lastRow.nextSibling);
          lastRow = r;
        });
        dispatch({
          type: "option",
          language,
          field: "index",
          optionIndex: firstPos - offset,
          value: newPosition - offset,
        });
      }
    };

    rows.forEach((r) => {
      r.addEventListener("dragover", dragOver, false);
      r.addEventListener("dragstart", dragStart, false);
      r.addEventListener("dragenter", dragEnter, false);
      r.addEventListener("dragend", dragEnd, false);
    });

    return () => {
      rows.forEach((r) => {
        r.removeEventListener("dragover", dragOver);
        r.removeEventListener("dragstart", dragStart);
        r.removeEventListener("dragenter", dragEnter);
        r.removeEventListener("dragend", dragEnd);
      });
    };
  });

  return (
    <Style ref={ref as React.RefObject<HTMLDivElement>}>
      <header>
        <ImageInput
          placeholder="Upload Image"
          value={state.image}
          width="5.7rem"
          height="5.7rem"
          onChange={(value) => dispatch({ type: "command", field: "image", language: "en", value: value })}
        />
        <div>
          <ErrorInput
            value={state.name[language].value}
            error={state?.errors?.name}
            placeholder="/name"
            onChange={(value) => {
              value = value.toLowerCase();
              if (value.length > 0) {
                if (!value.startsWith("/")) value = `/${value}`;
                if (!value.match(/^\/([\w-]{1,32}[ ]{0,1}){0,3}$/u)) return;
              }
              if (value.split(" ").length > 3) return;
              dispatch({ type: "command", field: "name", language, value });
            }}
          />
          <ErrorInput
            value={state.description[language].value}
            error={state?.errors?.description}
            onChange={(value) => dispatch({ type: "command", field: "description", language, value: value })}
            placeholder="Description of the command."
            maxLength={100}
          />
        </div>

        <div />
        <div>
          {state?.name && Object.keys(state.name).length === 1 ? (
            <button
              disabled={translate}
              onClick={() => {
                translateCommand(state).then((translated) =>
                  dispatch({
                    type: "init",
                    command: translated,
                  })
                );
                setTranslate(true);
              }}
            >
              Translate
            </button>
          ) : (
            <DropDown value={language} items={languages} onChange={(val) => setLanguage(val)} />
          )}
        </div>
      </header>

      <OptionRow language={language} />

      {options.map((o, i) => (
        <OptionRow
          key={i}
          language={language}
          option={o}
          onChange={(field, value, choiceIndex) => {
            if (typeof choiceIndex !== "undefined") {
              dispatch({ type: "choice", language, optionIndex: i, choiceIndex, value });
            } else {
              dispatch({ type: "option", language, optionIndex: i, field, value });
            }
          }}
          onDelete={() => dispatch({ type: "option.delete", optionIndex: i })}
        />
      ))}

      <div>
        <button
          disabled={hasErrors(state) || !state.changed}
          onClick={() => {
            saveCommand(state, history, dispatch, command, subcommand, subgroup);
            dispatch({ type: "command", language, field: "saved", value: true });
          }}
        >
          Save
        </button>
        {command?.id && (
          <button
            onClick={() =>
              persist.deleteCommand(command.id as string, subcommand, subgroup).then(() => {
                history.replace("/admin/commands");
              })
            }
          >
            Delete
          </button>
        )}
      </div>
    </Style>
  );
};

const dragPosition = (child: any) => {
  var i = 0;
  while ((child = child.previousSibling) != null) i++;
  return i;
};

const hasErrors = (command: ICommandEdit): boolean => {
  if (command.name["en"].value.length === 0) return true;
  if (command.errors && Object.entries(command.errors).length > 0) return true;
  return command.options.filter((o) => o.errors && Object.entries(o.errors).length > 0).length > 0;
};

const translateCommand = async (command: ICommandEdit): Promise<ICommandEdit> => {
  return fetch("/api/translateCommand.json", {
    method: "POST",
    body: JSON.stringify(command),
  }).then((response) => response.json());
};

const saveCommand = (
  command: ICommandEdit,
  history: any,
  dispatch: any,
  original?: ICommand,
  originalSubname?: string,
  originalSubgroup?: string
): void => {
  translateCommand(command).then((translated) => {
    dispatch({
      type: "init",
      command: translated,
    });

    let save: ICommand;
    try {
      save = convertToCommand(translated);
    } catch (ex) {
      console.error(ex);
      console.log(translated);
      return;
    }

    let path = getPath(save);

    if (command.image instanceof File) {
      let key = `commands/${path}.${command.image.name.split(".").pop()}`;
      persist.saveFile(command.image, key).then((url: string) => {
        if (save.subcommands) {
          Object.entries(save.subcommands)[0][1].image = url;
        } else {
          save.image = url;
        }
        persist.saveCommand(save, original?.id, originalSubgroup, originalSubname).then(() => {
          history.replace(`/admin/commands/${path}`);
        });
      });
    } else {
      persist.saveCommand(save, original?.id, originalSubgroup, originalSubname).then(() => {
        history.replace(`/admin/commands/${path}`);
      });
    }
  });
};

const getPath = (command: ICommand) => {
  if (command.subgroups) {
    const group = Object.values(command.subgroups)[0];
    return `${command.id}/${group.id}/${Object.values(group.subcommands)[0].id}`;
  } else if (command.subcommands) {
    return `${command.id}/${Object.values(command.subcommands)[0].id}`;
  } else {
    return `${command.id}`;
  }
};

const Style = styled.div`
  overflow: auto;
  padding: 40px;
  flex: 1;
  background: #3a3f58;
  color: #eee;
  margin: 20px 20px 20px 410px;
  border-radius: 16px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);

  &.move [draggable] * {
    pointer-events: none;
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  header {
    margin-bottom: 2rem;
    display: flex;

    > div:first-child {
      margin-right: 2rem;
    }

    > div:nth-child(2) {
      flex: 1;
      max-width: 35rem;

      div:nth-of-type(1) input {
        font-size: 1.5em;
        line-height: 1.5em;
        padding-top: 0.2em;
        padding-bottom: 0.2em;
        font-weight: bold;
        margin-bottom: 0.2em;
      }

      input:nth-of-type(2) input {
        font-size: 1em;
        line-height: 1.2em;
      }
    }
    > div:nth-child(3) {
      flex: 1;
    }

    > div:last-child {
      min-width: 180px;
      max-width: 180px;
      text-align: right;
    }
  }

  > div:last-child {
    margin-top: 1.5rem;
  }
`;

export default CommandPanel;
