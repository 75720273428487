import React, { useState, useReducer, useEffect } from 'react';
import styled from 'styled-components';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getDatabase, ref, get } from 'firebase/database';
import GuildChannel from './GuildChannel';
import GuildChannelReducer from '../../../data/GuildChannelReducer';
import IGuildChannelConfig, { IGuildChannel } from '../../../interfaces/IGuildChannelConfig';
import * as defaultChannel from '../../../data/DefaultChannels.json';

interface Props {
  guild: any;
}

interface SelectedChannel {
  platform: 'pc' | 'xbox' | 'psn' | 'nsw';
  index: number;
  isnew: boolean;
}

const Channels: React.FC<Props> = ({ guild }) => {
  const [channel, setChannel] = useState<SelectedChannel | null>();
  const [data, dispatch] = useReducer(GuildChannelReducer, initData(guild?.id));

  useEffect(() => {
    if (!guild?.id) return;
    get(ref(getDatabase(), `guilds/${guild.id}/channels`)).then((snapshot) => {
      if (snapshot.exists()) {
        const newData = snapshot.val();
        newData.id = guild.id;
        dispatch({ type: 'init', payload: newData });
      } else {
        dispatch({ type: 'init', payload: initData(guild.id) });
      }
    });
  }, [guild]);

  let categories = guild?.channels
    .filter((c: any) => c.type === 4)
    .map((c: any) => ({ ...c }))
    .sort((a: any, b: any) => a.position - b.position);

  guild?.channels.forEach((c: any) => {
    if (c.parent_id && c.type === 0) {
      const cat = categories.find((cat: any) => cat.id === c.parent_id);
      if (!cat) return;
      if (!cat.channels) cat.channels = [];
      cat.channels.push(c);
    }
  });

  categories = categories?.filter((c: any) => c?.channels?.length > 0);

  if (channel) {
    const configs = {
      pc: data.pc.channels[channel.index],
      xbox: data.xbox.channels[channel.index],
      psn: data.psn.channels[channel.index],
      nsw: data.nsw.channels[channel.index],
    };

    return (
      <GuildChannel
        configs={configs}
        guild={guild}
        isnew={channel.isnew}
        onChange={(field, value, platform) => {
          if (field === 'command') {
            if (value)
              Object.entries(configs).forEach(([platform, config]) => {
                dispatch({
                  platform: platform as any,
                  index: channel.index,
                  type: 'command',
                  command: value,
                  value: config.commands[value] ? false : true,
                });
              });
          } else {
            dispatch({
              platform: (platform as any) ?? channel.platform,
              index: channel.index,
              action: 'update',
              type: 'channel',
              field: field,
              value: value,
            });
          }
        }}
        onClose={() => setChannel(null)}
        onCancel={(data: any) => {
          Object.entries(data).forEach(([platform, config]) => {
            dispatch({
              platform: platform as any,
              index: channel.index,
              action: 'init',
              type: 'channel',
              payload: config as IGuildChannel,
            });
          });
          setChannel(null);
        }}
        onDelete={() => {
          Object.keys(configs).forEach((platform) => {
            dispatch({
              platform: platform as any,
              index: channel.index,
              action: 'delete',
              type: 'channel',
            });
          });
          setChannel(null);
        }}
      />
    );
  }

  const getChannelName = (obj: any): string => {
    if (obj.name) return obj.name;
    if (obj.id) return guild.channels.find((c: any) => c.id === obj.id)?.name ?? '';
    return '';
  };

  const ChannelList: React.FC<{ platform: 'pc' | 'xbox' | 'psn' | 'nsw' }> = ({ platform }) => {
    return (
      <div>
        <h2>
          {getChannelName(data[platform]?.category)}
          <div
            className="icon icon-math-plus"
            onClick={() => {
              const index = data[platform].channels.length;
              dispatch({ platform: 'pc', type: 'channel', action: 'create' });
              dispatch({ platform: 'xbox', type: 'channel', action: 'create' });
              dispatch({ platform: 'psn', type: 'channel', action: 'create' });
              dispatch({ platform: 'nsw', type: 'channel', action: 'create' });
              setChannel({ platform, index: index, isnew: true });
            }}
          />
        </h2>
        {data[platform]?.channels?.map((c: any, i: number) => (
          <div key={i} onClick={() => setChannel({ platform, index: i, isnew: false })}>
            <div>#{getChannelName(c)}</div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <Style>
      <h1>Recruitment Channels</h1>
      <p>Choose which channels to post new squads when created.</p>
      <div>
        {<ChannelList platform="pc" />}
        {<ChannelList platform="xbox" />}
        {<ChannelList platform="psn" />}
        {<ChannelList platform="nsw" />}
      </div>
      <div>
        <button
          onClick={() => {
            const functions = getFunctions();
            const discordGuildChannels = httpsCallable(functions, 'discordGuildChannels');
            return discordGuildChannels(data).then((result) => {
              console.log('saved');
            });
          }}
        >
          Save
        </button>{' '}
        <button>Reset</button>
      </div>
    </Style>
  );
};

const initData = (id: string): IGuildChannelConfig => {
  const data = JSON.parse(JSON.stringify(defaultChannel)).default;
  if (id) data.id = id;
  return data;
};

const Style = styled.div`
  tr *:first-child {
    width: 150px;
  }

  tr *:last-child {
    width: 250px;
  }

  td {
    padding: 2px 0;
  }
  > div:first-of-type {
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    > div {
      margin: 0 2px;
    }

    > div > div {
      display: table;
      border-radius: 5px;
      padding: 6px 14px;
      background: rgba(0, 0, 0, 0.5);
      line-height: 1.5rem;
      margin: 0 0 5px 0;
      cursor: pointer;
    }

    h2 {
      position: relative;
      margin-top: 10px;
      .icon {
        display: inline-block;
        vertical-align: middle;
        width: 28px;
        margin: 2px 2px 2px 10px;
        aspect-ratio: 1;
        cursor: pointer;
        border-radius: 5px;
        background: rgba(0, 0, 0, 0.1);
        text-decoration: none;
        color: #757ca1;

        &:hover {
          background: rgba(0, 0, 0, 0.25);
        }
      }
    }
  }

  > div:last-child {
    margin-top: 20px;
  }
`;

export default Channels;
