import { Route, RouteProps } from "react-router-dom";
import { ParsedToken } from "firebase/auth";
import Login from "../pages/login";

interface Props extends RouteProps {
  user?: ParsedToken | null;
}

const PrivateRoute: React.FC<Props> = ({ user, ...rest }: Props) => {
  if (!user) {
    return <Login />;
  }
  return <Route {...rest} />;
};

export default PrivateRoute;
