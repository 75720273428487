import React, { MouseEvent } from "react";
import styled from "styled-components";

interface Props {
  value: string;
  visible: boolean;
  placeholder?: string;
  onChange: (value: string) => void;
  onBlur: (event: any) => void;
  onFocus: (event: any) => void;
  onKeyDown: (event: any) => void;
}

interface StyleProps {
  Visible: boolean;
}

const SearchInput: React.FC<Props> = ({ value, visible, placeholder, onChange, onFocus, onBlur, onKeyDown }) => {
  const onClearFilter = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onChange("");
  };

  return (
    <Style Visible={visible}>
      <div className="icon icon-search" />
      <input
        type="text"
        value={value}
        placeholder={placeholder}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={(e) => onChange(e.target.value)}
        onKeyDown={onKeyDown}
      />
      {value.length > 0 && <button className="icon icon-status-failure" onMouseDown={onClearFilter} />}
    </Style>
  );
};

export default SearchInput;

const Style = styled.div<StyleProps>`
  display: flex;
  padding: 0.6rem 0 0.6rem 0.5rem;
  opacity: ${({ Visible }) => (Visible ? 1 : 0)};
  pointer-events: ${({ Visible }) => (Visible ? "inherit" : "none")};
  min-height: 1.25em;

  .icon {
    flex: 0;
    min-width: 1.25rem;
    background: none;
    border: none;
    cursor: pointer;
    border-radius: 0;
    box-shadow: none;
  }

  .icon-search {
    margin: 0 0.25rem 0 0;
  }

  input {
    padding: 0;
    outline: 0;
    margin: 0;
    border: 0;
    background-color: Transparent;
    cursor: default;
    line-height: 1.25em;
    color: var(--drop-color);
    min-width: 20px;

    &:hover {
      background-color: Transparent;
    }

    &::placeholder {
      opacity: 0.8;
    }
    flex: 1;
  }
`;
