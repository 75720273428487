export interface IOutline {
  type: number;
  title: string;
  children?: Array<IOutline>;
}

const documentOutline = (html: string): IOutline | null => {
  let outline: IOutline | null = null;
  const position: Array<IOutline> = [];

  let reg = /<h([1-5])[^>]*>(.+?)(?=<\/h[1-5]>)/gim;
  let result;
  while ((result = reg.exec(html)) !== null) {
    const current: IOutline = {
      type: parseInt(result?.[1] ?? "0"),
      title: innerText(result?.[2] ?? ""),
    };

    if (!outline) {
      outline = current;
    } else {
      while (position.length > 0 && position[position.length - 1].type >= current.type) {
        position.pop();
      }
      addChild(position.length === 0 ? outline : position[position.length - 1], current);
      position.push(current);
    }
  }
  return outline;
};

const innerText = (val: string): string => {
  return val
    .replace(/<[/\w][^>]+>/gi, "")
    .replace(/&amp;/g, "&")
    .trim();
};

const addChild = (parent: IOutline, child: IOutline) => {
  if (!parent.children) parent.children = [];
  parent.children.push(child);
};

export default documentOutline;
