import { ParsedToken } from "firebase/auth";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { getDatabase, ref, get } from "firebase/database";
import { installBot } from "../data/DiscordAuth";

interface Props {
  user?: ParsedToken | null;
}

interface Profile {
  name: string;
  platform: string;
}

const MenuBar = ({ user }: Props) => {
  const [profile, setProfile] = useState<Profile>();
  const discordName = (user?.name as string)?.split("#")[0] ?? "Unknown";

  useEffect(() => {
    if (!user) return;

    get(ref(getDatabase(), `users/${user.user_id}/warframe`)).then((snapshot) => {
      const data = snapshot.val();
      if (data) {
        const [key, value] = Object.entries(data)[0];
        setProfile({
          name: (value as any).name,
          platform: getPlatform(key),
        });
      }
    });
  }, [user]);

  return (
    <Style>
      <div>
        <Link to="/">
          <h1>Warframe</h1>
          <h2>Recritment Hub</h2>
        </Link>
      </div>
      <ul>
        <li>
          <Link to="/bot">Recruitment Bot</Link>
        </li>
        <li>
          <Link to="/servers/865904902941048862">Discord Server</Link>
        </li>
        <li>
          <Link to="/servers">Public Servers</Link>
        </li>
        <li>
          <Link to="/blog">Blog</Link>
        </li>
        {user?.administrator && (
          <li>
            <Link to="/admin/commands">Commands</Link>
          </li>
        )}
      </ul>
      <ul>
        <li>
          <Link to="/servers/add" onClick={() => installBot()}>
            Add to Server
          </Link>
        </li>
        <li>
          <Link to="/manage">Manage Servers</Link>
        </li>
      </ul>
      {user && (
        <div>
          <img alt={discordName} src={getPictureUrl(user?.picture as string)} />
          <b>{discordName}</b>
          <br />
          <span>{profile ? `${profile.name} [${profile.platform}]` : `Unverified`}</span>
        </div>
      )}
    </Style>
  );
};

const getPictureUrl = (url?: string) => {
  if (!url || url.endsWith("null.jpg")) {
    return "/artimus.png";
  } else {
    return url;
  }
};

const getPlatform = (key: string): string => {
  switch (key) {
    case "xb1":
      return "XBox";
    default:
      return "PC";
  }
};

const Style = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 70px;
  box-sizing: border-box;
  padding: 16px 20px;
  background: #1b1e2b;

  color: #eee;

  display: flex;

  a {
    display: inline-block;
    padding: 0 10px;
    color: #eeeeee;
    text-decoration: none;
  }

  h1 {
    margin: 0;
    font-size: 1.2rem;
    line-height: 1.1rem;
    letter-spacing: 2px;
    font-weight: 500;
  }

  > div:first-child {
    margin-right: 20px;
  }

  h2 {
    margin: 0;
    font-weight: normal;
    font-size: 0.9rem;
    color: #4d6ca2;
  }

  ul:nth-child(2) {
    flex: 1;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    display: inline-block;
    padding: 0;
    margin: 0;
    line-height: 38px;
    font-size: 0.9rem;
    cursor: pointer;
    border-radius: 10px;
    &:hover {
      background: rgba(0, 0, 0, 0.25);
    }
  }

  div:last-child {
    flex: 0;
    margin-left: 20px;
    position: relative;
    padding-left: 50px;
    white-space: nowrap;
    margin-right: 20px;
    line-height: 19px;
    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 34px;
      height: 34px;
      border-radius: 50%;
      object-fit: cover;
      border: 2px solid black;
    }
    span {
      font-size: 0.9rem;
      color: #4d6ca2;
    }
  }
`;

export default MenuBar;
