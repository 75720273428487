import React, { useEffect, useRef, RefObject } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";

interface Props {
  parent: RefObject<HTMLElement>;
}

const DropDownPanel: React.FC<Props> = ({ parent, children }) => {
  const ref = useRef<HTMLDivElement>(null);

  let mount = document.getElementById("dropdown-root");
  if (!mount) {
    mount = document.createElement("div");
    mount.id = "dropdown-root";
    document.body.appendChild(mount);
  }

  useEffect(() => {
    //Listen for window resize / scroll event
    let el = ref.current;
    let parel = parent.current;

    if (!el || !parel) return;

    const calpos = () => {
      if (!el || !parel) return;

      //Calculate Panel Position
      let rect = parel.getBoundingClientRect();
      el.style.left = rect.x + "px";
      el.style.width = rect.width + "px";

      let bottom = rect.bottom + el.offsetHeight;
      if (bottom < window.innerHeight) {
        el.style.top = window.scrollY + rect.bottom + 1 + "px";
      } else if (rect.top - el.offsetHeight - 1 > 0) {
        el.style.top = window.scrollY + rect.top - el.offsetHeight - 1 + "px";
      } else {
        el.style.top = window.scrollY + rect.top + rect.height / 2 - el.offsetHeight / 2 + "px";
      }
    };
    calpos();

    let change = new MutationObserver(() => requestAnimationFrame(calpos));
    change.observe(parel, { subtree: true, childList: true });

    let size = new ResizeObserver(calpos);
    size.observe(el);

    window.addEventListener("scroll", calpos);
    window.addEventListener("resize", calpos);

    el.style.opacity = "1";
    el.style.pointerEvents = "inherit";

    return () => {
      window.removeEventListener("scroll", calpos);
      window.removeEventListener("resize", calpos);
      size.disconnect();
      change.disconnect();
    };
  }, [parent]);

  return createPortal(
    <Style ref={ref} onMouseDown={(e) => e.preventDefault()}>
      {children}
    </Style>,
    mount,
  );
};

export default DropDownPanel;

const Style = styled.div`
  --drop-selected-back: var(--selected-back, #106ebe);
  --drop-selected-color: var(--selected-color, #fff);
  --drop-item-back: var(--item-back, #fff);
  --drop-item-color: var(--item-color, #000);
  --drop-hover-back: var(--hover-back, #deecf9);
  --drop-hover-color: var(--hover-color, #000);
  --drop-border-color: var(--dropdown-border, #ddd);
  --scroll-padding: 0.2em;

  position: absolute;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: var(--drop-item-back);
  box-sizing: border-box;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.35);
  transition: opacity 0.5s;
  border: 1px solid var(--drop-border-color) !important;

  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s;

  > *:nth-child(2) {
    min-height: 1px;
    margin: 0 10px;
    background-color: var(--drop-border-color);
    flex: 0;
  }

  > *:nth-child(3) {
    position: relative;
    flex: 0;
    margin: var(--scroll-padding);
  }
`;
