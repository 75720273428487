import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { useRouteMatch, useHistory } from "react-router-dom";
import { getAuth } from "firebase/auth";

import BlogTree from "./BlogTree";
import BlogEdit from "./BlogEdit";
import DocumentOutline, { IOutline } from "../../util/DocumentOutline";
import InsertAnchors from "../../util/InsertAnchors";
import { getDatabase, ref, onValue, set } from "firebase/database";

const Blog = () => {
  useRouteMatch();
  const history = useHistory();
  const [page, setPage] = useState<any>();
  const [admin, setAdmin] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  const pageRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    getAuth()
      .currentUser?.getIdTokenResult()
      .then((token) => token.claims.administrator && setAdmin(true));
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (edit || !pageRef.current) return;
    const links = pageRef.current.querySelectorAll("a[href]");
    const onClick = (e: any) => {
      const url = e.target.getAttribute("href");
      if (url.match(/(#)|(http(s{0,1}):\/\/)/gi)) return;
      e.preventDefault();
      history.push(url);
    };
    links.forEach((elem) => elem.addEventListener("click", onClick));
    return () => {
      links.forEach((elem) => elem.removeEventListener("click", onClick));
    };
  });

  const onPage = (id: string) => {
    if (page?.id === id) return;
    onValue(
      ref(getDatabase(), `/documentation/pages/${id}`),
      (s) => {
        let html = s.exists() ? s.val() : "<h1>Page Title</h1>";

        setPage({
          id,
          html,
          anchors: getAnchors(html),
          path: window.location.pathname,
        });

        if (!s.exists() && admin) {
          setEdit(true);
        } else if (edit) {
          setEdit(false);
        }
      },
      {
        onlyOnce: true,
      }
    );
  };

  return (
    <Style>
      <BlogTree path={page?.path} anchors={page?.anchors} setPage={onPage} />
      <div>
        {edit ? (
          <BlogEdit
            html={page?.html ?? ""}
            onCancel={() => setEdit(false)}
            onDelete={() => {}}
            onSave={(html) => {
              if (page.id) {
                set(ref(getDatabase(), `/documentation/pages/${page.id}`), html);
              }

              setPage({
                id: page.id,
                html: html,
                path: page.path,
                anchors: getAnchors(html),
              });
              setEdit(false);
            }}
          />
        ) : (
          page && (
            <div>
              {admin && (
                <button style={{ float: "right" }} onClick={() => setEdit(true)}>
                  Edit
                </button>
              )}
              <div ref={pageRef} dangerouslySetInnerHTML={{ __html: InsertAnchors(page.html ?? "") }} />
            </div>
          )
        )}
      </div>
    </Style>
  );
};

const getAnchors = (html: string | IOutline | null): any => {
  if (!html) return;

  const outline = (html as string).substr ? DocumentOutline(html as string) : (html as IOutline);
  if (!outline) return;

  const anchors: any = {};
  if (outline.children) {
    outline.children.forEach((child) => {
      const anchor: any = { url: child.title.toLowerCase().replace(/\s/g, "-") };
      anchors[child.title] = anchor;
      if (child.children) {
        anchor.children = getAnchors(child);
      }
    });
  }
  return anchors;
};

const Style = styled.div`
  display: flex;

  > div:last-child {
    padding: 40px;
    flex: 1;
    background: #3a3f58;
    color: #eee;
    margin: 20px 20px 20px 410px;
    border-radius: 16px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
    overflow: auto;
    box-sizing: border-box;
    min-height: calc(100vh - 111px);

    > div {
      max-width: 800px;
      margin: 0 auto;
    }
  }

  a {
    color: #68a59f;
    text-decoration: none;
  }
  blockquote {
    position: relative;
    background: #68a59f20;
    margin: 1em 0;
    padding: 1em;
    border-radius: 5px;
    border-left: 4px solid #68a59f;

    > p {
      margin: 0;
    }

    > h2,
    > h3 {
      margin: 0 0 0.5em 0;
      padding: 0;
      text-transform: uppercase;
      color: #68a59f;
    }
  }

  .figure {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1em 0;
    height: clamp(100px, 20vw, 300px);
    border-radius: 5px;
  }

  figure {
    margin: 0;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid #000;

    img {
      display: block;
      max-width: 30vw;
      max-height: 250px;
      margin: 0;
      background: black;
    }
    figcaption {
      text-align: center;
      font-size: 0.8em;
      padding: 0.8em;
      margin: 0;
      background: rgba(0, 0, 0, 0.5);
      color: #68a59f;
    }
  }

  kbd {
    display: inline-block;
    background-color: #eee;
    border-radius: 3px;
    border: 1px solid #b4b4b4;
    box-shadow: 0 1px 1px rgb(0 0 0 / 20%), 0 2px 0 0 rgb(255 255 255 / 70%) inset;
    color: #333;
    font-size: 0.85em;
    font-weight: 700;
    line-height: 1;
    padding: 5px 7px;
    white-space: nowrap;
    margin: 0 2px;
  }

  h1 {
    margin: 0 0 0.5em 0;
  }

  h2,
  h3 {
    margin: 1.5em 0 0.5em 0;
  }

  th {
    color: #68a59f;
    text-align: left;
    padding: 10px 0;
  }

  th,
  td {
    border-bottom: 1px solid #4d6ca2;
  }

  td {
    padding: 15px 0;
    p {
      margin: 0 0 10px 0;
    }
    td {
      padding: 5px 0;
      border: none;
    }
  }
`;

export default Blog;
