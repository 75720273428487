import ICommandEdit, { IOptionEdit } from "../interfaces/ICommandEdit";

interface CommandAction {
  type: "command";
  language: string;
  field: "name" | "description" | "image" | "saved";
  value: string | boolean | File;
}

interface OptionAction {
  type: "option";
  language: string;
  field: "name" | "title" | "description" | "type" | "required" | "index";
  value: string | boolean | number;
  optionIndex: number;
}

interface ChoiceAction {
  type: "choice";
  language: string;
  value: string | boolean | number;
  optionIndex: number;
  choiceIndex: number;
}

interface DeleteOptionAction {
  type: "option.delete";
  optionIndex: number;
}

interface InitAction {
  type: "init";
  command: ICommandEdit;
}

const CommandReducer = (
  state: ICommandEdit,
  action: CommandAction | InitAction | OptionAction | DeleteOptionAction | ChoiceAction,
): ICommandEdit => {
  switch (action.type) {
    case "init":
      return JSON.parse(JSON.stringify(action.command));
    case "choice":
    case "option":
      let option: IOptionEdit;
      if (action.optionIndex === state.options.length) {
        option = {
          name: {},
          title: {},
          type: "string",
          description: {},
          required: false,
          choices: [],
        };
      } else {
        option = state.options[action.optionIndex];
      }

      const newstate: ICommandEdit = {
        ...state,
        options: [...state.options],
        changed: true,
      };

      if (action.type === "option" && action.field === "index") {
        moveArrayIndex(newstate.options, action.optionIndex, action.value as number);
      } else {
        option = OptionReducer(option, action);
        if (action.optionIndex === state.options.length) {
          newstate.options.push(option);
        } else {
          newstate.options[action.optionIndex] = option;
        }
      }

      newstate.options.forEach((o, i) => {
        if (!o.errors) o.errors = {};
        if (!o.required && newstate.options.find((o, j) => o.required && i < j)) {
          o.errors["index"] = "Required items need to appear first";
        } else {
          delete o.errors.index;
        }
      });

      return newstate;
    case "option.delete":
      return {
        ...state,
        options: state.options.filter((o, i) => i !== action.optionIndex),
        changed: true,
      };
    case "command":
      let newState = { ...state, changed: true };
      switch (action.field) {
        case "name":
          const value = action.value as string;
          if (value.length > 0) {
            const parts = value.substr(1).split(" ");
            setLanguage(newState.command, action.language, parts[0] ?? "");
            if (parts.length > 2) {
              setLanguage(newState.subgroup, action.language, parts[1] ?? "");
              setLanguage(newState.subcommand, action.language, parts[2] ?? "");
            } else {
              setLanguage(newState.subcommand, action.language, parts[1] ?? "");
              setLanguage(newState.subgroup, action.language, "");
            }
          } else {
            setLanguage(newState.command, action.language, "");
            setLanguage(newState.subgroup, action.language, "");
            setLanguage(newState.subcommand, action.language, "");
          }
          setLanguage(newState[action.field], action.language, value);
          break;
        case "description":
          setLanguage(newState[action.field], action.language, action.value as string);
          break;
        case "image":
          newState.image = action.value as string;
          return newState;
        case "saved":
          newState.changed = false;
          newState.created = true;
          break;
        default:
          throw new Error("Unkown Command Action");
      }

      //Validate Command
      if (!newState.errors) newState.errors = {};

      if (newState.name["en"].value.length < 2) {
        newState.errors.name = "An command name is required.";
      } else {
        delete newState.errors.name;
      }

      if (newState.description["en"].value.length === 0) {
        newState.errors.description = "An command description is required.";
      } else {
        delete newState.errors.description;
      }

      return newState;
  }
};

const OptionReducer = (state: IOptionEdit, action: OptionAction | ChoiceAction): IOptionEdit => {
  let newState = { ...state };
  if (action.type === "choice") {
    newState.choices = newState.choices ? [...newState.choices] : [];
    if (action.choiceIndex === newState.choices.length) {
      newState.choices[action.choiceIndex] = {};
    }
    setLanguage(newState.choices[action.choiceIndex], action.language, action.value as string);
  } else {
    switch (action.field) {
      case "name":
      case "title":
      case "description":
        setLanguage(newState[action.field], action.language, action.value as string);
        break;
      case "type":
        switch (action.value) {
          case "string":
          case "number":
          case "role":
          case "relic":
          case "duration":
            newState.type = action.value;
            break;
          default:
            return state;
        }
        break;
      case "required":
        newState.required = action.value as boolean;
        break;
      default:
        throw new Error("Unkown Command Action");
    }
  }

  //Validate Option
  if (!newState.errors) newState.errors = {};

  if (newState.name[action.language]?.value.length > 0) {
    delete newState.errors.name;
  } else {
    newState.errors.name = "An option name is required.";
  }

  if (newState.title[action.language]?.value.length > 0) {
    delete newState.errors.title;
  } else {
    newState.errors.title = "An option title is required.";
  }

  if (newState.description[action.language]?.value.length > 0) {
    delete newState.errors.description;
  } else {
    newState.errors.description = "An option description is required.";
  }

  return newState;
};

const moveArrayIndex = (arr: Array<any>, from: number, to: number) => {
  while (from < 0) {
    from += arr.length;
  }
  while (to < 0) {
    to += arr.length;
  }
  if (to >= arr.length) {
    var k = to - arr.length;
    while (k-- + 1) {
      arr.push(undefined);
    }
  }
  arr.splice(to, 0, arr.splice(from, 1)[0]);
  return arr;
};

const setLanguage = (field: any, language: string, value: string) => {
  if (field[language]) {
    field[language].value = value;
    field[language].changed = true;
  } else {
    field[language] = {
      value: value,
      changed: true,
    };
  }
};

export default CommandReducer;
