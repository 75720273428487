import React, { useState } from "react";
import styled from "styled-components";
import { IOptionEdit } from "../../interfaces/ICommandEdit";
import ErrorInput from "../../components/ErrorInput";
import DropDown from "../../components/DropDown";
import ChoicesBaloon from "./choices";

interface Props {
  option?: IOptionEdit;
  language: string;
  onChange?: (
    field: "name" | "title" | "description" | "type" | "required",
    value: string | boolean | number,
    choiceIndex?: number,
  ) => void;
  onDelete?: () => void;
}

const types = ["String", "Number", "Role", "Relic", "Duration"].map((s) => ({
  label: s,
  value: s.toLowerCase(),
}));

const OptionRow = ({ language, option, onChange, onDelete }: Props) => {
  const [choices, setChoices] = useState<boolean>(false);

  if (!option) {
    return (
      <Row>
        <div></div>
        <div>
          <b>Name</b>
        </div>
        <div>
          <b>Type</b>
        </div>
        <div>
          <b>Title</b>
        </div>
        <div>
          <b>Description</b>
        </div>
        <div>
          <b>Required</b>
        </div>
        <div>
          <b>Choices</b>
        </div>
        <div></div>
      </Row>
    );
  }

  let style;
  if (option?.errors?.index) {
    style = { color: "red" };
  }

  return (
    <Row>
      <div draggable={option.type && true}>{option.type && <div className="icon icon-resize-grip"></div>}</div>
      <ErrorInput
        value={option.name?.[language]?.value ?? ""}
        error={option?.errors?.name}
        placeholder="option"
        maxLength={10}
        onChange={(value) => onChange && value.indexOf(" ") < 0 && onChange("name", value.toLowerCase())}
      />
      <DropDown
        value={option.type ?? "string"}
        items={types}
        placeholder="type"
        onChange={(value) => onChange && onChange("type", value)}
      />
      <ErrorInput
        value={option.title?.[language]?.value ?? ""}
        error={option?.errors?.title}
        placeholder="Embed Title"
        maxLength={15}
        onChange={(value) => onChange && onChange("title", value)}
      />
      <ErrorInput
        value={option.description?.[language]?.value ?? ""}
        error={option?.errors?.description}
        placeholder="Descripe the option"
        onChange={(value) => onChange && onChange("description", value)}
        maxLength={60}
      />
      <div>
        <div
          style={style}
          onClick={() => onChange && onChange("required", !option.required)}
          className={`icon icon-checkbox${option.required ? "" : "-empty"}`}
        />
      </div>
      <div>
        <div onClick={() => setChoices(true)} className="icon icon-view-list" />
      </div>
      <div>{option.type && <div className="icon icon-trash" onClick={(e) => onDelete && onDelete()}></div>}</div>

      {choices && (
        <ChoicesBaloon
          choices={option.choices.map((c) => c[language].value)}
          onChange={(val, index) => onChange && onChange("name", val, index)}
          onClose={() => setChoices(false)}
        />
      )}
    </Row>
  );
};

const Row = styled.div`
  display: flex;
  margin: 0 -22px;
  position: relative;

  &[dragging] {
    border-radius: 5px;
    background: rgb(27, 30, 43);
    box-shadow: 0 0 2px rgba(0, 0, 0);

    input {
      background-color: transparent;
      border-color: transparent;
      box-shadow: none !important;
    }
  }

  > div {
    display: inline-block;
    margin: 3px;
    vertical-align: top;
  }

  .icon {
    height: 2.4rem;
    cursor: pointer;
  }

  > div:nth-child(1) {
    width: 15px;
    .icon {
      cursor: move;
      transform: translateX(1px);
      &::before {
        font-size: 2rem;
        opacity: 0.8;
        color: #999;
      }
    }
  }

  > div:nth-child(2) {
    width: 150px;
  }

  > div:nth-child(3) {
    width: 130px;
  }

  > div:nth-child(4) {
    width: 200px;
  }

  > div:nth-child(5) {
    width: 400px;
    flex: 1;
  }

  > div:nth-child(6) {
    width: 100px;
    text-align: center;
  }

  > div:nth-child(7) {
    width: 100px;
    text-align: center;
  }

  > div:nth-child(8) {
    width: 40px;
    .icon:hover {
      color: #ff0000;
    }
  }

  input {
    box-sizing: border-box;
    width: 100%;
    font-size: 0.9rem;
    line-height: 1.2rem;
  }

  b {
    display: block;
    padding: 0.6rem 1rem;
    background: #1b1e2b;
    border-radius: 8px;
  }
`;

export default OptionRow;
