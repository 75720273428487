import React from "react";
import styled from "styled-components";
import { getDatabase, ref, set } from "firebase/database";
import { getAuth, User } from "firebase/auth";

const Ophy = () => {
  let user = getAuth().currentUser as User;
  return (
    <Style>
      <div>
        <h2>
          {user?.photoURL && <img alt="Your discord avatar" src={getPictureUrl(user.photoURL)} />}
          {user?.displayName?.split("#")[0]}
          <span>#{user?.displayName?.split("#")[1]}</span>
        </h2>

        <button
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            set(ref(getDatabase(), `pings/${new Date().getTime()}`), user.uid);
          }}
        >
          Ping Me
        </button>
      </div>
    </Style>
  );
};

const getPictureUrl = (url?: string) => {
  if (!url || url.endsWith("null.jpg")) {
    return "/artimus.png";
  } else {
    return url;
  }
};

const Style = styled.div`
  position: relative;
  height: calc(100vh - 70px);
  margin-top: 70px;
  box-sizing: border-box;

  > div {
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: absolute;
    width: 300px;
    height: 200px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #eeeeee;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 20px;
    box-shadow: 2px 2px rgba(0, 0, 0, 0.5);
  }

  h2 {
    font-weight: normal;
    margin: 0 0 10px 0;

    img {
      width: 50px;
      height: 50px;
      object-fit: cover;
      vertical-align: middle;
      margin-right: 20px;
      border-radius: 50%;
      border: 4px solid #000;
    }
    span {
      color: #880000;
    }
  }
`;

export default Ophy;
