import React, { useState } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";
import styled from "styled-components";
import ChannelDropDown from "./ChannelDropDown";
import Input from "../../../components/ErrorInput";

interface Props {
  guild: any;
}

const VerificationMessage: React.FC<Props> = ({ guild }) => {
  const [channel, setChannel] = useState("");
  const [message, setMessage] = useState("");
  const [saving, setSaving] = useState(false);

  return (
    <Style>
      <h2>Verification Message</h2>
      <ChannelDropDown channels={guild?.channels} value={channel} onChange={(val) => setChannel(val)} />
      <Input placeholder="Message" lines={4} value={message} onChange={(val) => setMessage(val)} />
      <div>
        <button
          disabled={saving}
          onClick={() => {
            setSaving(true);
            const functions = getFunctions();
            const discordGuildVerifyMessage = httpsCallable(functions, "discordGuildVerifyMessage");
            return discordGuildVerifyMessage({
              guild: guild.id,
              channel,
              message,
            })
              .then((result) => {
                setMessage("");
                setChannel("");
              })
              .finally(() => setSaving(false));
          }}
        >
          Post Message
        </button>
      </div>
    </Style>
  );
};

const Style = styled.div`
  max-width: 600px;

  textarea {
    margin-top: 6px;
    resize: none;
  }
`;

export default VerificationMessage;
