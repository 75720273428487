import { FunctionComponent, useEffect, useRef } from "react";
import styled from "styled-components";

interface Props {
  auto: boolean;
  maxHeight: number;
  onScroll: (pos: number) => void;
}

interface StyleProps {
  MaxHeight: number;
  Mobile: boolean;
}

const ScrollPanel: FunctionComponent<Props> = ({ children, auto = false, maxHeight = 0, onScroll }) => {
  let ref = useRef<HTMLDivElement>(null);

  let ismobile = /Android|webOS|iPhone|iPad|iPod|Opera Mini/i.test(navigator.userAgent);

  useEffect(() => {
    let inner = ref.current as any;
    inner._onScroll = onScroll;
    return () => {
      delete inner._onScroll;
    };
  });

  useEffect(() => {
    const inner = ref.current as any;
    if (!inner) return;

    const bar = inner.nextSibling as HTMLElement;
    const parent = inner.parentNode as HTMLElement;
    let locked = true;
    let autoInterval = 0;

    var style = window.getComputedStyle(parent, null);
    let autoGrow = parseInt(style.getPropertyValue("height")) === 0;

    const recalcBar = () => {
      if (!bar) return;
      let pct = inner.offsetHeight / inner.scrollHeight;
      if (pct < 1) {
        let height = inner.offsetHeight * pct;
        bar.style.height = `${height}px`;
        bar.style.display = "block";
      } else {
        bar.style.display = "none";
      }
    };

    const onResize = () => {
      if (autoGrow) {
        (async () => {
          parent.removeAttribute("style");
        })().then(() => {
          parent.style.height = `calc(var(--scroll-padding, 0) * 2 + ${inner.scrollHeight}px`;
          recalcBar();
        });
      } else {
        recalcBar();
      }

      if (auto && locked) {
        clearInterval(autoInterval);
        autoInterval = window.setInterval(() => {
          const maxScroll = inner.scrollHeight - inner.offsetHeight;
          const move = (maxScroll - inner.scrollTop) / 5;
          if (move < 0.5) {
            inner.scrollTop = maxScroll;
            clearInterval(autoInterval);
          } else {
            inner.scrollTop += Math.round(move);
          }
        }, 40);
      }
    };

    const onWheel = (e: WheelEvent) => {
      e.preventDefault();
      e.stopPropagation();

      clearInterval(autoInterval);

      let delta = e.deltaY * -1;
      if (e.deltaMode === 1) {
        delta *= 35;
      }

      let move = -100 * (delta / 100);
      if (move < 0) {
        locked = false;
      }
      inner.scrollTop += move;
    };

    const onScrollInternal = () => {
      let maxScroll = inner.scrollHeight - inner.offsetHeight;
      let percent = inner.scrollTop / maxScroll;
      if (percent >= 1) {
        percent = 1;
        locked = true;
      }

      if (bar) {
        const barTop = (inner.offsetHeight - bar.offsetHeight) * percent;
        bar.style.top = `calc(var(--scroll-padding, 0) + ${barTop}px`;
      }

      if (inner._onScroll) inner._onScroll(inner.scrollTop);
    };

    let resize: ResizeObserver;
    let change: MutationObserver;
    if (typeof ResizeObserver === "function") {
      resize = new ResizeObserver(onResize);
      resize.observe(inner);
    }
    if (typeof MutationObserver === "function") {
      change = new MutationObserver(onResize);
      change.observe(inner, { subtree: true, childList: true, attributes: true });
    }

    inner.addEventListener("wheel", onWheel);
    inner.addEventListener("scroll", onScrollInternal);

    onResize();

    return () => {
      if (resize) resize.disconnect();
      if (change) change.disconnect();
      inner.removeEventListener("wheel", onWheel);
      inner.removeEventListener("scroll", onScrollInternal);
    };
  }, [auto]);

  return (
    <Panel MaxHeight={maxHeight} Mobile={ismobile}>
      <div ref={ref}>{children}</div>
      {!ismobile && <div className="bar" />}
    </Panel>
  );
};

export default ScrollPanel;

const Panel = styled.div<StyleProps>`
  position: relative;
  flex: auto;

  max-height: calc(var(--scroll-padding, 0) * 2 + ${({ MaxHeight }) => MaxHeight + "px"});

  > div:first-child {
    position: absolute;
    overflow: ${({ Mobile }) => (Mobile ? "auto" : "hidden")};
    top: var(--scroll-padding, 0);
    left: var(--scroll-padding, 0);
    right: var(--scroll-padding, 0);
    bottom: var(--scroll-padding, 0);
  }

  > .bar {
    display: none;
    position: absolute;
    top: var(--scroll-padding, 0);
    right: 2px;
    height: 50px;
    min-height: 20px;
    width: 5px;
    border-radius: 5px;
    background: var(--scroll-color, white);
    transition: opacity 0.5s;
    opacity: 0;
  }

  &:hover > .bar {
    opacity: 0.5;
  }
`;
