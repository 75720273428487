import React from "react";
import styled from "styled-components";

interface Props {
  value: string;
  error?: string;
  placeholder?: string;
  maxLength?: number;
  lines?: number;
  onChange: (value: string) => void;
}

const ErrorInput = React.forwardRef<HTMLInputElement, Props>(
  ({ value, lines = 1, error, placeholder, maxLength, onChange }, ref) => {
    return (
      <Style data-error={error}>
        {lines > 1 ? (
          <textarea
            rows={lines}
            placeholder={placeholder}
            value={value}
            maxLength={maxLength}
            onChange={(e) => onChange(e.target.value)}
            onDragStart={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          />
        ) : (
          <input
            ref={ref}
            placeholder={placeholder}
            type="text"
            value={value}
            maxLength={maxLength}
            onChange={(e) => onChange(e.target.value)}
            onDragStart={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          />
        )}
      </Style>
    );
  },
);

const Style = styled.div`
  textarea,
  input {
    position: relative;
    border: 0;
    display: block;
    color: #eee;
    outline: 0;
    margin: 0;
    padding: 0.6rem 1rem;
    background: #303244;
    border-radius: 8px;
    border: 1px solid #292d3e;
    box-sizing: border-box;
    font-family: "Open Sans", sans-serif;
    box-shadow: 0 0 2px rgba(0, 0, 0);

    width: 100%;
    &:focus {
      background: #292d3e;
    }
  }

  &[data-error] input {
    border-color: red;
  }
`;

export default ErrorInput;
