import React, { useState, useEffect, useRef, CSSProperties } from 'react';
import ScrollPanel from '../ScrollPanel';
import styled from 'styled-components';
import Item from './types/Item';

interface Props {
  items: Array<Item>;
  itemTemplate: any;
  itemClick: (data: any, value: string) => void;
  visibleItems: number;
  highlightIndex: number;
}

const ItemList: React.FC<Props> = ({ items, itemTemplate, itemClick, visibleItems, highlightIndex }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [renderIndex, setRenderIndex] = useState<number>(0);
  const [itemHeight, setItemHeight] = useState<number>(0);

  useEffect(() => {
    const calc = () => {
      if (!ref.current?.firstChild) return;
      let el = ref.current.firstChild as Element;
      let rect = el.getBoundingClientRect();
      setItemHeight(rect.height);
    };
    calc();
    window.addEventListener('resize', calc);
    return () => {
      window.removeEventListener('resize', calc);
    };
  }, []);

  useEffect(() => {
    if (!ref.current?.parentNode) return;

    let focusIndex = highlightIndex;
    if (highlightIndex < 0) {
      focusIndex = items.findIndex((item) => item.selected);
      if (renderIndex === 0 && focusIndex >= 0) focusIndex += Math.floor(visibleItems / 2);
    }

    const parent = ref.current.parentNode as Element;
    if (focusIndex <= renderIndex) {
      parent.scrollTop = focusIndex * (itemHeight + 1);
    } else if (focusIndex >= renderIndex + visibleItems) {
      parent.scrollTop = (focusIndex - visibleItems + 1) * (itemHeight + 1);
    }
    //eslint-disable-next-line
  }, [itemHeight, items.length, highlightIndex]);

  const onScroll = (pos: number) => {
    let newIndex = Math.floor(pos / (itemHeight + 1));
    if (newIndex !== renderIndex) setRenderIndex(newIndex);
  };

  let style: CSSProperties | undefined = undefined;

  if (itemHeight) {
    style = {
      height: `${items.length * (itemHeight + 1) - 1}px`,
      paddingTop: `${renderIndex * (itemHeight + 1)}px`,
      boxSizing: 'border-box',
    };
  }

  const renderItems = items.filter((item, i) => renderIndex <= i && i <= renderIndex + visibleItems);
  const Template = itemTemplate;

  return (
    <ScrollPanel auto={false} maxHeight={itemHeight ? (itemHeight + 1) * visibleItems - 1 : 150} onScroll={onScroll}>
      <Container ref={ref} style={style}>
        {renderItems.map((item, i) => (
          <Template
            key={i + renderIndex}
            item={item.data}
            selected={item.selected}
            highlighted={item.highlighted}
            onClick={() => itemClick(item.data, item.value)}
          />
        ))}
      </Container>
    </ScrollPanel>
  );
};

export default ItemList;

const Container = styled.div`
  > *:not(:last-child) {
    margin-bottom: 1px;
  }
`;
