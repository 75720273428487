import { useEffect, useState } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";
import styled from "styled-components";
import { Link } from "react-router-dom";

interface IGuild {
  id: string;
  name: string;
  icon: string;
}

interface GuildProps {
  guild: IGuild;
}

const ChooseGuild = () => {
  const [guilds, setGuilds] = useState<Array<IGuild>>();

  useEffect(() => {
    const data = sessionStorage.getItem("guilds");
    if (data) {
      setGuilds(JSON.parse(data));
    } else {
      const functions = getFunctions();
      const discordGuilds = httpsCallable(functions, "discordGuilds");
      discordGuilds().then((result) => {
        sessionStorage.setItem("guilds", JSON.stringify(result.data));
        setGuilds(result.data as Array<IGuild>);
      });
    }
  }, []);

  return (
    <Style>
      <div>
        <h1>Manage Servers</h1>
        <p>
          Select one of the servers you can manage below. If the bot is not yet installed, we'll take you through the
          process of installing the bot, and setting up your recruitment channels.
        </p>
      </div>
      <div>{guilds && guilds.map((g) => <Guild key={g.id} guild={g} />)}</div>
    </Style>
  );
};

const Guild = ({ guild }: GuildProps) => {
  const style = guild.icon
    ? {
        backgroundImage: `url("https://cdn.discordapp.com/icons/${guild.id}/${guild.icon}.png?size=512")`,
      }
    : {
        backgroundImage: `url("/artimus.png")`,
        filter: "grayscale(100%)",
        opacity: ".5",
      };

  return (
    <div>
      <Link to={`/manage/${guild.id}`}>
        <div>
          <div style={style}></div>
          <div>{guild.name}</div>
        </div>
      </Link>
    </div>
  );
};

const Style = styled.div`
  > div {
    margin: 40px auto;
    max-width: clamp(20px, 90vw, 1200px);
    box-sizing: border-box;

    p {
      color: #ccc;
    }

    &:first-child {
      padding: 0 8px;
    }

    &:last-child {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    }

    > div {
      flex: 0 0 auto;
      padding: 16px 8px 0 8px;

      > a {
        background-color: #1b1e2b;
        border-radius: 15px;
        cursor: pointer;
        display: block;
        position: relative;
        transition: color 0.125s, background-color 0.125s, box-shadow 0.125s, transform 0.125s;

        text-decoration: none;
        color: #ccc;

        &:hover {
          background-color: #141424;
          background-image: none;
          box-shadow: 0 8px 16px rgb(0 0 0 / 30%);
          text-decoration: none;
          transform: translateY(-5px);
          color: #eee;

          &::after {
            position: absolute;
            top: 100%;
            right: 0;
            left: 0;
            height: 8px;
            content: "";
          }
        }

        > div {
          padding: 15px;

          > div:first-child {
            align-items: center;
            background-size: cover;
            border-radius: 12px;
            box-sizing: border-box;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            margin-bottom: 15px;
            position: relative;
            width: 100%;
            height: auto;

            &::after {
              content: "";
              display: block;
              padding-bottom: 100%;
            }
          }

          > div:last-child {
            overflow: hidden;
            text-align: center;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 0.8rem;
          }
        }
      }
    }
  }
`;

export default ChooseGuild;
