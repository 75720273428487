import styled from "styled-components";
import { discordLogin } from "../data/DiscordAuth";

const LoginPage = () => {
  return (
    <Style>
      <div>
        <h1>Admin Account Required</h1>
        <p>The requested page requires an adiminstrator's account. </p>

        <p>If you have recently been promoted to an administrator you will need to login again.</p>
        <button onClick={discordLogin}>Login</button>
      </div>
    </Style>
  );
};

const Style = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;

  h1 {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 2px;
    margin: 0 0 30px 0;
    text-align: center;
  }

  p {
    color: #4d6ca2;
  }

  button {
    margin: 30px 0 0 0;
  }

  > div {
    flex: 0;
    min-width: 450px;
    color: #eeeeee;
    background: #1b1e2b;
    border-radius: 20px;
    padding: 50px;

    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  }
`;

export default LoginPage;
