import React from "react";
import { Link, useRouteMatch } from "react-router-dom";
import styled from "styled-components";

interface Props {
  guild: any;
}

const GuildMenu: React.FC<Props> = ({ guild }) => {
  const { url } = useRouteMatch();
  const style = guild?.icon
    ? {
        backgroundImage: `url("https://cdn.discordapp.com/icons/${guild.id}/${guild.icon}.png?size=512")`,
      }
    : {
        backgroundImage: `url("/artimus.png")`,
        filter: "grayscale(100%)",
        opacity: ".5",
      };

  return (
    <Style>
      <div>
        <div style={style} />
        <h2>{guild?.name ?? "loading..."}</h2>
        <span>{guild?.approximate_member_count.toLocaleString() ?? "XX"} members</span>
      </div>
      <ul>
        <li>
          <Link
            to={`${url}/dashboard`}
            data-selected={window.location.pathname.endsWith("/dashboard") ? true : undefined}
          >
            <div className="icon icon-dashboard" />
            Dashboard
            <br />
            <span>Overview of you server</span>
          </Link>
        </li>
        <li>
          <Link to={`${url}/verify`} data-selected={window.location.pathname.endsWith("/verify") ? true : undefined}>
            <div className="icon icon-policy" />
            User Verification <br />
            <span>Assign roles / nickname</span>
          </Link>
        </li>
        <li>
          <Link
            to={`${url}/channels`}
            data-selected={window.location.pathname.endsWith("/channels") ? true : undefined}
          >
            <div className="icon icon-feed" />
            Channels <br />
            <span>Recruitment Channels</span>
          </Link>
        </li>
        <li>
          <Link to={`${url}/roles`} data-selected={window.location.pathname.endsWith("/roles") ? true : undefined}>
            <div className="icon icon-shield" />
            Role Notifications <br />
            <span>Associate Roles with Squads</span>
          </Link>
        </li>
      </ul>
    </Style>
  );
};

const Style = styled.div`
  position: fixed;
  flex: 0;
  top: 71px;
  left: 0;
  width: 390px;
  height: calc(100vh - 71px);
  padding: 1rem 0.6rem 1rem 0.3rem;
  background: #1b1e2b;
  color: #4d6ca2;

  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  &:not(:hover) > *::-webkit-scrollbar-thumb {
    background: transparent;
    opacity: 0;
  }

  > div {
    > div {
      width: 150px;
      height: 150px;
      margin: 20px auto;
      border-radius: 15px;
      background-size: cover;
    }
    margin-bottom: 1.6rem;
    text-align: center;
  }

  h2 {
    position: relative;
    margin: 0;
    color: #757ca1;
    flex: 0;
    padding: 0 1rem;
    font-size: 1.6rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: auto;
    padding: 0 0.9rem 0 1rem;
    flex: 1;
  }

  li {
    margin-bottom: 10px;
  }

  a {
    display: block;
    position: relative;
    line-height: 1.2rem;
    font-weight: bold;
    color: #68a59f;
    cursor: pointer;
    padding: 0.8rem 0.8rem 0.8rem 4rem;
    border-radius: 8px;
    overflow: hidden;
    white-space: nowrap;
    background: rgba(0, 0, 0, 0.1);
    transition: color 0.25s;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
    text-decoration: none;

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }

    &[data-selected="true"],
    &:hover {
      background: rgba(0, 0, 0, 0.25);
      span {
        color: #b2ccd6;
      }
    }

    .icon {
      position: absolute;
      width: 2.4rem;
      height: 2.4rem;
      object-fit: cover;
      left: 0.8rem;
      top: 0.8rem;
      border-radius: 30%;
      &:before {
        font-size: 1.5rem;
        color: #757ca1;
      }
    }
  }

  span {
    color: #4d6ca2;
    font-size: 1rem;
    font-weight: normal;
    text-overflow: ellipsis;
    transition: color 0.25s;
    font-size: 0.9rem;
  }
`;

export default GuildMenu;
