import React, { useRef, useState } from "react";
import styled from "styled-components";
import { Editor } from "@tinymce/tinymce-react";
import image from "./ImagePlugin";

interface Props {
  html: string;
  onSave: (html: string) => void;
  onCancel: () => void;
  onDelete?: () => void;
}

const BlogEdit: React.FC<Props> = ({ html, onSave, onCancel, onDelete }) => {
  const editorRef = useRef<any>(null);
  const [saving, setSaving] = useState(false);

  const onInternalSave = () => {
    if (!editorRef.current) {
      onSave(html);
      return;
    }

    setSaving(true);
    image
      .removeEditable(editorRef.current.getContent())
      .then((newHtml) => onSave(newHtml))
      .catch((ex) => {
        console.error(ex);
        setSaving(false);
      });
  };

  return (
    <Style>
      <Editor
        apiKey="rklnopmgqwavwyykkwfx31lndk6ddvggus2yqlmj9z37283y"
        onInit={(evt, editor) => {
          editor.focus();
          editorRef.current = editor;
        }}
        initialValue={image.addEditable(html)}
        init={{
          menubar: false,
          inline: true,
          skin: "oxide-dark",
          plugins: ["advlist autolink lists link table code", "insertdatetime media table paste wordcount"],
          toolbar:
            "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent table | link anchor localImage | fullscreen code ",
          toolbar_sticky_offset: 70,

          setup: (editor) => {
            editor.ui.registry.addButton("localImage", {
              icon: "image",
              tooltip: "Insert Current Date",
              disabled: true,
              onAction: () => {
                image.selectImage().then((html) => {
                  if (!html) return;
                  editor.insertContent(html);
                  console.log("Selected Image");
                });
              },
              onSetup: (buttonApi) => {
                var editorEventCallback = (eventApi: any) => {
                  buttonApi.setDisabled(eventApi.element.nodeName.toLowerCase() === "time");
                };
                editor.on("NodeChange", editorEventCallback);

                /* onSetup should always return the unbind handlers */
                return function (buttonApi) {
                  editor.off("NodeChange", editorEventCallback);
                };
              },
            });
          },
          style_formats_merge: true,
          style_formats: [
            {
              title: "Command",
              inline: "kbd",
            },
          ],
        }}
      />
      <button disabled={saving} onClick={() => onInternalSave()}>
        Save
      </button>
      <button onClick={() => onCancel()}>Cancel</button>
      {onDelete && <button onClick={() => onDelete()}>Delete</button>}
    </Style>
  );
};

const Style = styled.div`
  > div {
    min-height: calc(100vh - 240px);
    text-align: left;
    padding: 10px;
    border-radius: 5px;
    border: 2px solid transparent;
    box-sizing: border-box;

    &:focus,
    &:focus-visible {
      outline: none;
      border-color: #68a59f;
    }

    margin: -12px -12px 1em -12px;
  }

  > button:nth-of-type(3) {
    float: right;
    background: #df6071;
  }
`;

export default BlogEdit;
