import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link, useRouteMatch, useHistory } from "react-router-dom";
import { getDatabase, ref, onValue, off } from "firebase/database";
import IDocumentTree from "../../interfaces/IDocumentationTree";

interface Anchor {
  [key: string]: {
    url: string;
    children?: Anchor;
  };
}

interface Props {
  anchors?: Anchor;
  path: string;
  setPage: (id: string) => void;
}

const BlogTree: React.FC<Props> = ({ anchors, path, setPage }) => {
  const { url } = useRouteMatch();
  const history = useHistory();

  const [tree, setTree] = useState<IDocumentTree | null>();

  useEffect(() => {
    const treeRef = ref(getDatabase(), `/documentation/tree/en${url}`);
    onValue(treeRef, (snapshot) => setTree(snapshot.val()));
    return () => off(treeRef);
  }, [url]);

  if (tree) {
    const parts = window.location.pathname.substr(1).split("/");
    parts.shift();
    let doc: IDocumentTree | undefined = tree;
    let pagePath = `/${doc.path}`;

    parts.forEach((path) => {
      doc = doc?.children?.find((d) => {
        return d.path === path;
      });
      if (doc) pagePath += `/${doc.path}`;
    });

    if (!doc) {
      setTimeout(() => history.replace(pagePath), 4);
    } else if (doc.id) {
      const id = doc.id;
      setTimeout(() => setPage(id), 4);
    } else {
      do {
        doc = doc.children?.[0];
        if (doc) pagePath += `/${doc.path}`;
      } while (doc);

      if (pagePath !== window.location.pathname) {
        setTimeout(() => history.replace(pagePath), 4);
      }
    }
  }

  return <Style>{tree && <TreeMenu node={tree} anchorPath={path} anchors={anchors} />}</Style>;
};

interface TreeMenuProps {
  node: IDocumentTree;
  path?: string;
  level?: number;
  anchorPath: string;
  anchors?: Anchor;
}

const TreeMenu: React.FC<TreeMenuProps> = ({ node, path = "", level = 0, anchorPath, anchors }) => {
  const nodePath = `${path}/${node.path}`;

  return (
    <>
      {level > 0 &&
        (level > 1 ? (
          <Link to={nodePath} data-selected={anchorPath === nodePath ? true : undefined}>
            {node.title}
          </Link>
        ) : (
          <h2>{node.title}</h2>
        ))}
      {node.children && (
        <ul>
          {node.children.map((child) => {
            return (
              <li key={child.path}>
                <TreeMenu node={child} path={nodePath} level={level + 1} anchorPath={anchorPath} anchors={anchors} />
              </li>
            );
          })}
        </ul>
      )}
      {anchorPath === nodePath && anchors && <AnchorMenu anchors={anchors} />}
    </>
  );
};

interface AnchorMenuProps {
  anchors: Anchor;
}

const AnchorMenu: React.FC<AnchorMenuProps> = ({ anchors }) => {
  return (
    <ul>
      {Object.entries(anchors).map(([title, value]) => (
        <li key={value.url}>
          <a href={`#${value.url}`}>{title}</a>
          {value.children && <AnchorMenu anchors={value.children} />}
        </li>
      ))}
    </ul>
  );
};

const Style = styled.div`
  overflow: auto;
  position: fixed;
  width: 390px;
  height: calc(100vh - 71px);
  background: #1b1e2b;
  box-sizing: border-box;

  > ul {
    margin: 20px 16px;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin-bottom: 1em;
  }

  li {
    padding: 0 0 0 1em;
    margin: 0;

    li {
      font-size: 0.95em;
    }
  }

  a {
    display: block;
    padding: 8px 15px;
    border-left: 3px solid transparent;
    border-radius: 2px 5px 5px 2px;
    margin: 0 0 3px 0;

    &[data-selected],
    &:hover {
      background: rgba(0, 0, 0, 0.25);
      border-color: #68a59f;
    }
  }

  h2 {
    margin: 1.5em 0 5px 0;
    font-size: 1.2em;
    text-transform: uppercase;
    color: #4d6ca2;
  }
`;

export default BlogTree;
