const discord = {
  client_id: "891606903136862239",
  redirect_uri: "https://warframehub.com/oauth",
  scope: "identify email guilds applications.commands",
  response_type: "code",
  prompt: "none",
};

export const discordLogin = () => {
  const params = new URLSearchParams(discord);
  params.append("state", window.location.pathname);
  window.location.replace(`https://discord.com/api/oauth2/authorize?${params}`);
};

export const installBot = (permissions: number = 535193480273, guild?: string) => {
  //535193480273
  const params = new URLSearchParams(discord);
  params.set("scope", `${discord.scope} bot`);
  if (guild) params.append("guild_id", guild);
  params.append("permissions", `${permissions}`);
  params.append("state", window.location.pathname);
  window.location.replace(`https://discord.com/api/oauth2/authorize?${params}`);
};
